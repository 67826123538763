import React from "react";
import "./ContributeFooter.css";

const ContributeFooter = () => {
  return (
    <>
    <section className="contribute_footer">
      <div class="row">
        <div class="card ">
          <h2 class="title">Tap here to pay </h2>
          <button type="button" class="btn btn-danger btn-md">
            Tap Here
          </button>
          <article className="Payment-content">
            <h2>Our Promise</h2>
            <p>
              We Promise that 50% of whatever you contribute goes into the help
              of the needful.
            </p>
            <p>
              Yes, we at RollingNotes.in don’t limit our vision to provide free
              service but also have expanded our thoughts to help the backward
              section in this run. Any contribution you make, Small or Big, will
              make a BIG SMILE on the faces of those who seek to, but aren’t
              able to.
            </p>
            <p>
              Let’s make a small effort in making this country even better,
              together, with our CONTRIBUTE Scheme.
            </p>
          </article>
        </div>
      </div>
      </section>
    </>
  );
};

export default ContributeFooter;
