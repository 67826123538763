import { configureStore } from '@reduxjs/toolkit'
import globalAdsSlice from './globalAdsSlice'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import  timerSlices from './slices/timerSlices'
import StudyMaterialslice from './slices/StudyMaterialslice'

export const store = configureStore({
  reducer: {
    adsReducer: globalAdsSlice,
    timers : timerSlices,
    study:StudyMaterialslice,
  },
})
