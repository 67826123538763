import React from "react";

import CurrentCalander from "./CurrentCalander.jsx";
import "./CurrentAffair.Module.css";
function CurrentAffair() {
  return (
    <>
      <section className="container ">
        <div className="headings">
          <h4>rollingnotes'</h4>
          <h5> Daily Current AFFAIRS</h5>
          <p className="quote">
            “If you fulfill your obligations everyday you don't need to worry
            about the future.”
          </p>
          <p className="quote-by"> ― Jordan Peterson</p>
        </div>
        {/* image container */}
        <div className="container image-container">
          <img src={require("../../assets/images/current-affair.gif")} alt="" />
        </div>
        <CurrentCalander />
      </section>
    </>
  );
}

export default CurrentAffair;

