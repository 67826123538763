import React, { useEffect, useState } from 'react'

const GoToTop = () => {

    const [isVisible, setIsVisible] = useState(false);


    const listenToScroll = ()=>{
        let heightToHidden = 20;
        const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    }

    const handleTop =()=>{
        window.scrollTo({top:0,left:0,behavior:'smooth'})
    }


    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
      }, []);


  return (
    <>

      {
        isVisible && (<div className="top_button">
        <i className="fa-sharp fa-solid fa-arrow-up" onClick={handleTop}></i>
        </div>)
      }
    </>
  )
}

export default GoToTop