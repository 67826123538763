import React, { useEffect } from "react";
import "./TestimonialCards.css";



const TestimonialCards = ({data,index})=>{


  return (
    <>
    <div className="container my-5">
     
      <div className="row">
        <div className="col-md-12">
        <figure className="snip1192">
        <blockquote style={{height:"250px"}}>
          {data?.description}{" "}
        </blockquote>
        <div className="author">
          <img
            src={data?.profile}
            alt="sq-sample1"
          />
          <h5>
            {data?.name} <span> {data?.title}</span>
          </h5>
        </div>
         </figure>
        </div>
      </div>
    </div>
      
    </>
  );
}


export default TestimonialCards;
