import axios from "axios";
import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import parse from 'html-react-parser';

export const Privacy = () => {
  
   const [newData, setNewData] = useState([])
  const getdata = async () => { 
      try{
         const {data} =  await axios.get("https://app.rollingnotes.in/backend/get_privacy_policy.php")
         console.log(data.data)
         setNewData(data.data);
      }
      catch(error){
         console.log(error.message)
      }
  }
  useEffect(() => {
         getdata();
  }, [])
  
  return (
    <>
          {newData.map((el,i)=>{
            return<div className="conatiner border p-3" >
               <div className="row">
                <div className="col-12">
                  <h1>{el.title}</h1>
                </div>
                </div> 
              {parse(el.policies)}
            </div>
              
            
          })}   
    </>
  );
};
