import React, { useEffect, useState } from "react";

import "./Footer.css";
import FooterLogo from "../../assets/images/rolling-notes-logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import AndroidImg from '../../assets/images/google-play-badge.png';
import AmazonImg from '../../assets/images/amazon-appstore-badge.png';

const Footer = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("https://rollingnotes.in/app/backend/api_v2/footer_api.php")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log("error=>", error);
      });
  }, []);
  return (
    <>
      <footer>
        <section className="footer-top-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <div className="footer-wrapper">
                  <div className="footer-logo-wrapper">
                    <img src={FooterLogo} alt="" />
                    <h6>RollingNotes</h6>
                    <p>WE ROLL, YOU NOTE</p>
                  </div>
                  <ul className="footer-logo-wrapper-ul">
                    <li>
                      <Link to="https://wa.me/916002072097" target={"_blank"}>
                        <i className="fa-brands fa-whatsapp"></i> +91 6002072097
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:enquiry@rollingnotes.in">
                        <i className="fa-solid fa-envelope"></i>{" "}
                        enquiry@rollingnotes.in
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-solid fa-location-dot"></i> Assam
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="footer-wrapper">
                  <h6>Links</h6>

                  {data?.Links?.length > 0 &&
                    data?.Links?.map((item, index) => {
                      return (
                        <div key={index}>
                          <ul className="footer-wrapper-ul">
                            <li>
                              <Link to={item.link}>{item.name}</Link>
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                </div>
              </div>
            <div className="col-lg-3 col-md-3">
              <div className="footer-wrapper">
                <h6>MoreFromUs</h6>

                {data?.MoreFormUs?.length > 0 &&
                  data?.MoreFormUs?.map((item, index) => {
                    return (
                      <div key={index}>
                        <ul className="footer-wrapper-ul">
                          <li>
                            <Link to={item.link}>{item.name}</Link>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </div>

           {/* //Yha Cde paste krdena smjhe ki nhi */}
           <div className="col-lg-3 col-md-3">
<div className="footer-wrapper">
   <h6>Download our free app, Now!</h6>
 <ul className="footer-logo-wrapper-ul">
    <li><Link to="https://play.google.com/store/apps/details?id=com.rollingnotes&pli=1" target={'_blank'}><img src={AndroidImg} alt="" /></Link></li>
 <li><Link to="https://www.amazon.com/gp/product/B07ZN2QFDQ" target={'_blank'}><img src={AmazonImg} alt="" /></Link></li>

</ul>
 </div>
 </div>




            </div>
          </div>
        </section>
        <section className="footer-bottom-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <p>Copyright © 2023-2024 RollingNotes, All Rights Reserved.</p>
              </div>
              <div className="col-lg-6 col-md-6">
                <ul className="footer-social-links">
                  <li>
                    <Link
                      to="https://www.instagram.com/rollingnotes.in/"
                      target={"_blank"}
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/channel/UCqeTQZMarE6kTUO4aPCvXtw"
                      target={"_blank"}
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.facebook.com/rollingnotesdib"
                      target={"_blank"}
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://twitter.com/NotesRolling"
                      target={"_blank"}
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.pinterest.com/rollingnotes/"
                      target={"_blank"}
                    >
                      <i className="fa-brands fa-pinterest"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://wa.me/916002072097" target={"_blank"}>
                      <i className="fa-brands fa-whatsapp"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;