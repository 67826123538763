import React from 'react';
import "./Banner.css";
import Video from '../../../assets/videos/Homepage.mp4';
import AndroidImg from '../../../assets/images/google-play-badge.png';
import AmazonImg from '../../../assets/images/amazon-appstore-badge.png';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <>
    <section className="banner-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="banner-content-wrapper">
                        <h1>RollingNotes</h1>
                        <h2>We Roll, You Note</h2>
                        <p>NorthEast India's First E-Learning App and Website</p>
                    
                        <div className="row">
                            <div className="col-lg-6 col-md-6 text-md-end text-center">
                                <Link to="https://play.google.com/store/apps/details?id=com.rollingnotes" target={'_blank'}>
                                    <img src={AndroidImg} alt="" />
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6 text-md-start text-center">
                                <Link to="https://www.amazon.com/gp/product/B07ZN2QFDQ" target={'_blank'}>
                                    <img src={AmazonImg} alt="" />
                                </Link>
                            </div>
                        </div>

                        <h3>100% FREE</h3>
                    </div>
                </div>                
                <div className="col-lg-6 col-md-6 text-center">
                    <video autoPlay="autoplay" loop="loop" muted>
                        <source src={Video} type="video/mp4" />
                    </video>
                </div>                
            </div>
        </div>
    </section>
    </>
  )
}

export default Banner;