import React from "react";
import "./EarnWith.css";
function EarnForm() {
  return (
    <>
      <div
        class="container"
        style={{
          paddingTop: "2.5em",
          paddingBottom: "2.5em",
          fontWeight: "700",
        }}
      >
        <header class="header">
          <h1 id="title" class="text-center" className="Form-heading">
            send us your materials here and start earning
          </h1>
        </header>
        <div class="form-wrap">
          <form id="survey-form">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label id="name-label" for="name">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter your name"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label id="email-label" for="email">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label id="phone-label" for="phone">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Enter your nmber"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Leave Message</label>
                  <textarea
                    id="comments"
                    class="form-control"
                    name="comment"
                    placeholder="Enter your comment here..."
                    style={{ height: "9em" }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="uploadSection">
              <label
                id="comments"
                style={{ fontSize: "15px", fontWight: "100" }}
              >
                Upload any relevent file here
              </label>
              {/* <Button
                component="label"
                variant="contained"
                size="small"
                sx={{
                  color: "white",
                  ml: "1rem",
                }}
              >
                Browse
                <input hidden accept="image/*" multiple type="file" />
              </Button> */}
              <button className="btn btn-primary btn-sm">Browse</button>
            </div>
            <div class="row ">
              <div
                class="col-md-12 "
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <button
                  type="submit"
                  id="submit"
                  class="btn btn-primary btn-block"
                  style={{
                    marginTop: "1.3em",
                    width: "50%",
                    maxWidth: "150px",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EarnForm;
