import React, { useState, useEffect } from "react";
import "./Modals.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import AdsComponent from "../../AdsComponent/AdsComponent";

const Modals = ({
  show,
  close,
  institute,
  StudyMaterial,
  addval,
  InstituteAdsVal,
  StudyMaterialAdsVal,
  comesFrom,
  InstituteId,
  timerData,
}) => {
  const study = useSelector((state) => state?.study);
  const navigate = useNavigate();
  const [timeleft, setTimeleft] = useState(parseInt(timerData?.timing));

  const redirectedToParticularPage = () => {
    let data = { institute: institute, InstituteId: InstituteId };
    if (institute.length) {
      if (comesFrom === "institute") {
        // let splitData = institute.split(" ");
        // let removeSpaceInInstitute = institute.replaceAll(" ", "-");
        navigate(`/${data?.institute}/${InstituteId}`, { state: data });
      } else
        navigate(`/studymaterial/${study.module_name}`, { state: institute });
    }
  };

  useEffect(() => {
    if (timeleft === 0) {
      setTimeleft(null);
      close();
      redirectedToParticularPage();
    }

    if (!timeleft) return;

    const intervalId = setInterval(() => {
      setTimeleft(timeleft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeleft]);

  useEffect(() => {
    if (
      (InstituteAdsVal === "0" && comesFrom === "institute") ||
      addval === "0"
    ) {
      return redirectedToParticularPage();
    } else if (
      (StudyMaterialAdsVal === "0" && comesFrom === "studyMaterial") ||
      addval === "0"
    ) {
      return redirectedToParticularPage();
    }
  }, []);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {/* <Helmet>
     <title></title>
     </Helmet> */}

      {StudyMaterialAdsVal === "1" && comesFrom === "studyMaterial"
        ? show && (
            <>
              <div className="modalContainer">
                <div
                  className="modal_show"
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* <!-- ass --> */}

                  <AdsComponent dataAdSlot="2597424317" />

                  <footer className="modal_footer">
                    {/* <div className="model_button">
                      <button className="modal-close" onClick={() => close()}>
                        Close
                      </button>
                    </div> */}
                    {timeleft != null && (
                      <h3>00:{timeleft < 10 ? "0" + timeleft : timeleft}</h3>
                    )}
                  </footer>
                </div>
              </div>

              <div className="modal_overlay" onClick={handleOverlayClick} />
            </>
          )
        : InstituteAdsVal === "1" && comesFrom === "institute"
        ? show && (
            <>
              <div className="modalContainer">
                <div
                  className="modal_show"
                  onClick={(e) => e.stopPropagation()}
                >
                  <AdsComponent dataAdSlot="2597424317" />
                  <footer className="modal_footer">
                    {/* <div className="model_button">
                      <button className="modal-close" onClick={() => close()}>
                        Close
                      </button>
                    </div> */}
                    {timeleft != null && (
                      <h3>00:{timeleft < 10 ? "0" + timeleft : timeleft}</h3>
                    )}
                  </footer>
                </div>
              </div>
              <div className="modal_overlay" onClick={handleOverlayClick} />
            </>
          )
        : null}
    </>
  );
};

export default Modals;
