import React from 'react'

const SolvedPapers = () => {
  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>Solved Question Papers</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="first_year mt-5 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="first_year_info text-center">
                  <p>Choose Your University or Board :</p>
                  <div className="button_div">
                    <button className="btn btn-primary mx-2">
                      <div className="button-inner">Dibrugarh University</div>
                    </button>
                    <button className="btn btn-primary">
                      <div className="button-inner">A.H.S.E.C (Assam)</div>
                    </button>
                  </div>
                </div>
                <div className="divider"></div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default SolvedPapers