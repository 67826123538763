import React from "react";
import "./Study material/StudyMaterial.css";
// import FaFacebookF from "react-icons/fa";

import {
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Lottie from "react-lottie";
import animation from "../Lotties/Facebook.json";
import animation1 from "../Lotties/Watsap.json";
import animation2 from "../Lotties/Twitter.json";

const ShareWith = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animation1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animation2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const iconstyle = {
    height:"100px",
    width:"100px",
  }

  return (
    <>
      <section className="py-2">
        <div className="container-fluid">
          <div className="share-box py-3">
            <div className="row">
              <div className="col-md-12">
                <div className="share-with-heading text-center">
                  <h1>Follow Our Socials</h1>
                </div>
              </div>
            </div>
            <div className="mt-2 py-3">
              <div className="row">
                <div className="col-4 col-sm-4 col-md-4">
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <div className="Social-icons">
                      <span>
                        {/* style = {{marginTop:'-105px'}} */}

                        <FacebookShareButton url={props.pdf}>
                          {/* <FacebookIcon size={80} round={true} /> */}
                          <Lottie
                            options={defaultOptions}
                            style={iconstyle}

                          />
                        </FacebookShareButton>
                      </span>
                    </div>
                    {/* </a> */}
                  </div>
                </div>
                <div className="col-4 col-sm-4 col-md-4">
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    {/* <a
                    href="https://www.instagram.com/rollingnotes.in/"
                    target="_blank"
                  > */}
                    <div className="Social-icons">
                      <span>
                        {/* <i className="fa-brands fa-instagram text-light"></i> */}
                        {/* <InstapaperShareButton url = "https://www.instagram.com/rollingnotes.in/">
                          <InstapaperIcon size ={80} round={true}/>
                        </InstapaperShareButton> */}
                        <WhatsappShareButton url={props.pdf}>
                          {/* <WhatsappIcon size={80} round={true} /> */}
                          {/* <i class="watsap-icon fa-brands fa-square-whatsapp"></i>{" "} */}
                          <Lottie
                            options={defaultOptions1}
                            style={iconstyle}

                          />
                        </WhatsappShareButton>
                      </span>
                    </div>
                    {/* </a> */}
                  </div>
                </div>

                <div className="col-4 col-sm-4 col-md-4">
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    {/* <a href="https://twitter.com/NotesRolling" target="_blank"> */}
                    <div className="Social-icons">
                      <span>
                        <TwitterShareButton url={props.pdf}>
                          {/* <TwitterIcon size={80} round={true} /> */}
                          {/* <i class="twitter-icon fa-brands fa-x-twitter"></i> */}
                          {/* <i class="twitter-icon fa-brands fa-twitter"></i> */}
                          <Lottie
                            options={defaultOptions2}
                            style={iconstyle}
                          />
                        </TwitterShareButton>
                      </span>
                    </div>
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShareWith;
