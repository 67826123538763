import React, { useRef } from "react";
import "./../Previous/Previous.css";

const BoardAHSEC = () => {
  const scollToRef1 = useRef();
  const scollToRef2 = useRef();

  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>AHSEC</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="first_main">
        <section className="first_year my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="first_year_info text-center">
                  <p>Select Your Class/Standard Here :</p>
                  <div className="button_div">
                    <button className="btn btn-primary mx-2" onClick={()=>scollToRef1.current.scrollIntoView()}>
                      <div className="button-inner">Class 11 (First year)</div>
                    </button>
                    <button className="btn btn-primary" onClick={()=>scollToRef2.current.scrollIntoView()}>
                      <div className="button-inner">Class 12 (Second year)</div>
                    </button>
                  </div>
                </div>
                <div className="divider" ref={scollToRef2}></div>
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div className="">Class 12</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for Assam Higher Secondary
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">H.S. Second Year</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="Faq py-1 my-1">
          <div className="container">
            <div className="headingblack text-center mb-2 pb-2">
              <h5>commerce</h5>
            </div>
            <div className="row">
              <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    Previous Year Question Papers
                  </div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Solved Question papers</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">NCERT Solutions</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">
                    Important Question(Exam POV)
                  </div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Short Questions</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Long Questions</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Flowchart</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Syllabus</div>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="second_main" ref={scollToRef1}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="divider"></div>
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div className="">Class 11</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for Assam Higher Secondary
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">H.S. First Year</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="Faq py-1 my-1">
          <div className="container">
            <div className="headingblack text-center mb-2 pb-2">
              <h5>commerce</h5>
            </div>
            <div className="row">
              <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    Previous Year Question Papers
                  </div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Solved Question papers</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">NCERT Solutions</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">
                    Important Question(Exam POV)
                  </div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Short Questions</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Long Questions</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Syllabus</div>
                </button>
              </div>
            </div>
          </div>
          <div className="divider"></div>
        </section>
      </div>
      <section className="college_intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                  <h2 className="my-2">
                    About Assam Higher Secondary Education Council (AHSEC)
                  </h2>
                  <p className="my-4">
                    AHSEC an acronym for Assam Higher Secondary Education
                    Council is a board of education under the&nbsp;state
                    government of Assam. It is the regulating body for higher
                    secondary (10+2 stage) education in the state of
                    Assam.&nbsp; The Assam Higher Secondary Education Council
                    was established by the Assam government on 1 June 1984 to
                    regulate, supervise and develop the system of higher
                    secondary education in the State of Assam.
                  </p>
                </div>
                <div className="content mt-5">
                  <h5 className="my-2">
                    Functions Of Assam Higher Secondary Education Council
                  </h5>
                  <p className="my-4">
                    One of the main operations of the AHSEC (Assam Higher
                    Secondary Education Council) is to enforce the rules and
                    regulations, that have been set for the schools that are
                    affiliated with the Assam Board. Other functions also
                    include prescribing the syllabus, devising courses of study
                    like the textbooks, conducting exams and so on.
                  </p>
                </div>
                <div className="content mt-5">
                  <h5 className="my-2">
                    Exam Conducted by Assam Higher Secondary Education Council
                  </h5>
                  <p className="my-4">
                    The Assam Higher Secondary Education Council conducts Class
                    12 exam every year.&nbsp;AHSEC or the Assam Higher Secondary
                    Education Council supervises, regulates, and develops the
                    system of 12th standard in the state. The 12th standard
                    exams in Assam are conducted for Science, Commerce, Arts and
                    Vocational Groups.
                  </p>
                  <p>
                    STRUCTURE OF THE COUNCIL
                    <br />
                    The Council has a full time Chairman who is the chief
                    executive of the Council. The Chairman is appointed by the
                    State Government for a term of three years, which may be
                    extended for another period of three years. The Secretary of
                    the Council is the principal Administrative officer and is
                    responsible for all matters pertaining to general
                    Administration, Establishment, legal matters, Accounts,
                    Public relation, Grievance redressel, affiliation and
                    property of the Council. The Controller of Examinations is
                    responsible for all matters concerning examination
                    administration as well as confidential work of examination,.
                    And the other positions are as below:-
                  </p>
                  <ul>
                    <li>Joint Secretary</li>
                    <li>Deputy Secretary</li>
                    <li>Academic officer</li>
                    <li>Assistant Secretary</li>
                    <li>Internal Auditor</li>
                    <li>Superintendent</li>
                    <li>Assistant Superintendent</li>
                    <li>PA</li>
                    <li>U D A</li>
                    <li>L D A</li>
                    <li>Grade IV</li>
                    <li>Duftry</li>
                    <li>Sweeper</li>
                    <li>Driver</li>
                  </ul>
                </div>
                <div className="content mt-5"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoardAHSEC;
