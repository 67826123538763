import React, { useEffect, useState } from "react";
// import { FaqData } from "../../utils/FaqData";
import AccordionItems from "./AccordionItems";

const AccordionCard = () => {
  // states
  const [FaqData, setFaqData] = useState(null);

  const getFaqData = () => {
    const faq = fetch(
      "https://rollingnotes.in/app/backend/api_v2/fetch_faq.php"
    )
      .then((res) => res.json(res))
      .then((res) => setFaqData(res?.faq))
      .catch((errr) => console.log(errr));
  };

  const [clicked, setClicked] = useState(null);

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);

    // console.log(setClicked(index))
  };

  // useeffect
  useEffect(() => {
    getFaqData();
  }, []);
  return (
    <>
      <div className="accordion">
        {Array.isArray(FaqData) &&
          FaqData.length > 0 &&
          FaqData.map((faq, index) => {
            return (
              <AccordionItems
                faq={faq}
                key={index}
                onToggle={() => handleToggle(index)}
                active={clicked === index}
              />
            );
          })}
      </div>
    </>
  );
};

export default AccordionCard;
