import React, { useEffect } from "react";

const AdsComponent = (props) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      console.log("Ads fetched by us !!!");
    } catch (e) {
      console.log("Error Occured in fetching ads .... ");
    }
  }, []);

  return (
    <>
      <div
        style={{ minWidth: "280px", minHeight: "280px", position: "relative" }}
        className="border border-primary"
      >
        <div
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-2178006875471084"
            data-ad-slot={props?.dataAdSlot}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
      </div>
    </>
  );
};

export default AdsComponent;
