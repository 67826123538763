import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import "./Header.css";
import AppContext from "../../feature/ContextForHeader/ContextForheader";
import { useContext } from "react";
import { setActiveLink } from "react-scroll/modules/mixins/scroller";
import axios from "axios";
import { Helmet } from "react-helmet";

const Header = () => {
  const {stateforheader,setStateForHeader} = useContext(AppContext);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [activeitem,setActiveItem] = useState();
  const [showTitle , setShowTitle] = useState(false)
  useEffect(() => {
    axios
      .get("https://rollingnotes.in/app/backend/api_v2/header_api.php")
      .then((res) => {
        setData(res?.data);
        setStateForHeader(0);
      })
      .catch((error) => {
        console.log("error=>", error);
      });
  }, []);


  
  return (
    <>
      <section className="navabar_menu">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <Link to="/" className="logo d-flex">
              <div className="img_logo text-end">
                <img
                  src={require("../../assets/images/logo.png")}
                  width="50px"
                  alt=""
                />
              </div>
              <h3>
                <span>Rolling Notes</span>
              </h3>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              
            >
              <span className="navbar-toggler-icon"></span>
            </button>
           

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ms-auto">
                {Array?.isArray(data) &&
                  data?.length &&
                  data?.map((item, index) => {
                    if (item?.parent === 0) {
                      return (
                        <div key={index}>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to={item?.link}
                            >
                              {item?.name}
                            </Link>
                          </li>
                        </div>
                      );
                    } else if (item?.parent === 1) {
                      return (
                        <div key={index}>
                          <li className="nav-item dropdown">
                            <Link
                              className="nav-link dropdown-toggle"
                              to={item?.link}
                              id="navbarDropdownMenuLink"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {item?.name}
                            </Link>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdownMenuLink"
                            >
                              {Array?.isArray(item?.sub_menu) &&
                                item?.sub_menu?.length &&
                                item?.sub_menu?.map((sub_item, index) => {
                                  return (
                                    <li key={index}>
                                      <Link
                                        className={`dropdown-item ${setActiveItem === item?.sub_item ? "active-item-header":""}`}
                                        to={
                                          item?.name === "Board/University"
                                            ? "/institute"
                                            : item?.name ===
                                              "Other Study Materials"
                                            ? "/studymaterial"
                                            : item?.name ===
                                                "Previous Question Papers" &&
                                              "/previousyearquestion"
                                        }
                                        state={
                                          item?.name ===
                                          "Previous Question Papers"
                                            ? {
                                                study:
                                                  "Previous Year Question Papers",
                                                institute:
                                                  (sub_item?.name === "AHSEC" &&
                                                    "AHSEC (Assam)") ||(sub_item?.name === "ICAI – CA Students" &&
                                                    "ICAI") ||
                                                  (sub_item?.name ===
                                                    "Guahati University" &&
                                                    "Guwahati University") ||
                                                  sub_item?.name,
                                              }
                                            : sub_item?.name ===
                                              "NCERT Solutions"
                                            ? "NCERT Solution"
                                            : sub_item?.name ===
                                              "Guahati University"
                                            ? "Guwahati University"
                                            : sub_item?.name === "AHSEC"
                                            ? "AHSEC (Assam)"
                                           : sub_item?.name
                                        }
                                        onClick={() => {
                                          if(stateforheader === 0){
                                          setStateForHeader(1);
                                          
                                        }
                                        else{
                                          setStateForHeader(0);
                                        }
                                        setActiveItem(sub_item?.name);
                                        }}
                                        
                                      >
                                        
                                        {sub_item?.name}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                        </div>
                      );
                    } else {
                      return null; 
                    }
                  })}
              </ul>
            </div>
          </div>
        </nav>
      </section>
       {/* For DeskTop View Start */}


      {/* For Mobile View Start */}
      {/* <section className="navabar_menu_mobile">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <Link to="/" className="logo d-flex align-items-center">
            <div className="img_logo text-center">
              <img src={require("../../assets/images/logo.png")}  width="50px" alt="" />
            </div>
              <h3>
                <span>Rolling Notes</span>
              </h3>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav m-auto gap-5">
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Previous Papers
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="previous-paper/dibrugarh"
                      >
                        Dibrugarh
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="previous-paper/ahsec">
                        AHSEC
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="previous-paper/cbse">
                        CBSE
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="previous-paper/guwahati"
                      >
                        Guahati
                      </Link>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="previous-paper/nios"
                        >
                          NIOS
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="previous-paper/icia"
                        >
                          ICIA-CA
                        </Link>
                      </li>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Board/University
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <Link className="dropdown-item" to="boards/dibrugarh">
                        Dibrugarh
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="boards/ahsec">
                        AHSEC
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="boards/cbse">
                        CBSE
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="boards/guwahati">
                        Guwahati
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Other Study Materials
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <Link className="dropdown-item" to="study-material/ncert">
                        NCERT Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="study-material/solved-papers"
                      >
                        Solved Question Papers
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="study-material/important-questions"
                      >
                        Important Questions
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="study-material/flowchart"
                      >
                        Flowcharts
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="study-material/study-notes"
                      >
                        Study Notes
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="study-material/short-question"
                      >
                        Short Questions
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="study-material/long-question"
                      >
                        Long Questions
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="study-material/syllabus"
                      >
                        Syllabus
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/feedback">
                    Feedback
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/career">
                    Career
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section> */}
       {/* For Mobile View Start */}
       
    </>
  );
};

export default Header;
