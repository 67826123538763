import React from "react";
import "./Career.css";
import ReCAPTCHA from "react-google-recaptcha";

const Career = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="form_wrapper flex-md-row flex-sm-column flex-column">
              <div className="col-md-6 col-sm-12 col-12">
                <div className="career_img p-5">
                  <img src={require("../../assets/images/m3.png")} alt="" />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <div className="career_form">
                <h2 class="mb-3">Career With Us</h2>
                  <form action="">
                    <div className="form-row mb-3">
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        className="form-control"
                      />
                    </div>
                    <div className="form-row mb-3">
                      <input
                        type="email"
                        placeholder="Enter Your Email"
                        className="form-control"
                      />
                    </div>
                    <div className="form-row mb-3">
                      <input
                        type="password"
                        placeholder="Enter Your Password"
                        className="form-control"
                      />
                    </div>
                    <div className="form-row mb-3">
                      <textarea
                        name=""
                        id=""
                        cols="10"
                        rows="0"
                        className="form-control"
                        placeholder="How do you feel you can be helpful to us? Attach anything you want us to see, below"
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <input
                        className="form-control"
                        type="file"
                        id="formFileMultiple"
                        multiple
                      />
                    </div>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} />
                    <div className="submit_button career my-4 text-center">
                      <button className="btn btn-primary w-50">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
