import React from "react";
import { Link } from "react-router-dom";
import "./Feedback.css";
import ReCAPTCHA from "react-google-recaptcha";
const Feedback = () => {
  return (
    <>
      <section className="feedback">
        <div className="container mt-5">
          <div className="row">
            <div
              className="heading text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1>Feedbak/Suggestions</h1>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <div className="feedback1">
                <Link>
                  <h2>feedback@rollingnotes.in</h2>
                </Link>
              </div>
              <div className="feedback_number text-center">
                <span>
                  <Link>
                    <i className="fa-brands fa-whatsapp mx-2"></i>6002072097
                  </Link>
                </span>
              </div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <div className="feedback_icons my-2">
                <ul>
                  <li className="insta">
                    <Link>
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                  </li>
                  <li className="fab">
                    <Link>
                      <i className="fa-brands fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li className="ytube">
                    <Link>
                      <i className="fa-brands fa-youtube"></i>
                    </Link>
                  </li>
                  <li className="twit">
                    <Link>
                      <i className="fa-brands fa-twitter"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feedback_form mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div
                className="form-title mt-5"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <p>
                  Have any suggestions for us?&nbsp;
                  <span>
                    Well, you’re invited to share it with us. Help us improve to
                    just the way you like.
                  </span>
                </p>
              </div>
              <div
                className="border"
                data-aos="fade-right"
                data-aos-duration="5000"
              ></div>
              <form action="">
                <h2 data-aos="fade-down" data-aos-duration="1000">
                  Feedback Form
                </h2>
                <div className="row my-3">
                  <div className="col-md-6">
                    <label htmlFor="" className="mb-2">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="" className="mb-2">
                      Phone
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Optional"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-12">
                    <label htmlFor="" className="mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-12">
                    <label htmlFor="" className="mb-2">
                      Message
                    </label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      className="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} />
                <div className="submit_button my-4">
                  <button className="btn btn-primary w-100">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Feedback;
