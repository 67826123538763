import React, { useRef } from "react";

const BoardDibrugarh = () => {

  const scollToRef1 = useRef();
  const scollToRef2 = useRef();
  const scollToRef3 = useRef();
  const scollToRef4 = useRef();
  const scollToRef5 = useRef();

  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>Dibrugarh University</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="first_main 12">
        <section className="first_year my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="first_year_info text-center">
                  <p>Select Your Course Here :</p>
                  <div className="button_div">
                    <div className="row">
                      <div className="col-md-3">
                        <button className="btn btn-primary mx-2" onClick={()=>scollToRef1.current.scrollIntoView()}>
                          <div className="button-inner">B.Com</div>
                        </button>
                      </div>
                      <div className="col-md-2">
                        <button className="btn btn-primary" onClick={()=>scollToRef2.current.scrollIntoView()}>
                          <div className="button-inner">BBA</div>
                        </button>
                      </div>
                      <div className="col-md-2" onClick={()=>scollToRef3.current.scrollIntoView()}>
                        <button className="btn btn-primary">
                          <div className="button-inner">Others</div>
                        </button>
                      </div>
                      <div className="col-md-2" onClick={()=>scollToRef4.current.scrollIntoView()}>
                        <button className="btn btn-primary">
                          <div className="button-inner">MBA</div>
                        </button>
                      </div>
                      <div className="col-md-3" onClick={()=>scollToRef5.current.scrollIntoView()}>
                        <button className="btn btn-primary">
                          <div className="button-inner">M.Com</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider" ref={scollToRef1}></div>
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">B.Com</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for Dibrugarh University
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">
                      Bachelor of Commerce
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>commerce</h5>
            </div>
            <div className="row">
              <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    Previous Year Question Papers
                  </div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">
                    Important Questions (Exam POV)
                  </div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Solved Questions Papers</div>
                </button>
                <br />
                <button className="btn btn-primary mt-3">
                  <div className="button-inner">Study Notes</div>
                </button>
                <button className="btn btn-primary mt-3">
                  <div className="button-inner">Syllabus</div>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="second_main 11" ref={scollToRef5}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="divider"></div>
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">M.Com</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for Dibrugarh University
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Master of Commerce</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>commerce</h5>
            </div>
            <div className="row">
              <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    Previous Year Question Papers
                  </div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Solved Question Papers</div>
                </button>
              </div>
            </div>
          </div>
          <div className="divider"></div>
        </section>
      </div>

      <div className="second_main 10" ref={scollToRef2}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12"></div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">BBA</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for Dibrugarh University
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">
                      Bachelor of Business Administration
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>commerce</h5>
            </div>
            <div className="row">
              <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">Important Questions</div>
                </button>
                <button className="btn btn-primary">
                  <div className="button-inner">Study Notes</div>
                </button>
              </div>
            </div>
          </div>
          <div className="divider"></div>
        </section>
      </div>

      <div className="second_main 9" ref={scollToRef4}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12"></div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">MBA</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for Dibrugarh University
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">
                      Master of Business Administration
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>commerce</h5>
            </div>
            <div className="row">
              <div className="button_div text-center">
                <button className="btn btn-primary">
                  <div className="button-inner">Study Notes</div>
                </button>
              </div>
            </div>
          </div>
          <div className="divider"></div>
        </section>
      </div>

      <div className="second_main 8" ref={scollToRef3}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12"></div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">DIBRUGARH</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for Dibrugarh University
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Other Courses</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>Science, Arts, Commerce & Law</h5>
            </div>
            <div className="row">
              <div className="button_div text-center">
                <button className="btn btn-primary">
                  <div className="button-inner">
                    Previous Year Question Papers
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="divider"></div>
        </section>
      </div>
      <section className="college_intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                  <h2 className="my-2">About Dibrugarh University</h2>
                </div>
                <div className="content mt-5">
                  <p className="my-4">
                    Dibrugarh University is a university in the Indian state of
                    Assam. It was set up in 1965 under the provisions of the
                    Dibrugarh University Act, 1965,[1] enacted by the Assam
                    Legislative Assembly. It is a teaching-cum-affiliating
                    university.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Location and Campus</h5>
                  <p>
                  The Dibrugarh University Campus is located at Rajabheta, 5 km from Dibrugarh Town (27° 29′ North Latitude and 94° 55′ East Longitude) and is spread over an area of 500 acres.[2] The NH15 (old NH37) bisects the main campus from the other, the latter primarily comprising teacher’s and officer’s residence.
                  </p>
                  <p>The Dibrugarh district is well known for its vast mineral resources (including oil, natural gas and coal), flora and fauna and many tea plantations. The diverse tribes with their distinct dialects, customs, traditions and culture make this area attractive to students of anthropology and sociology, art and culture.</p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Accreditation</h5>
                  <p>
                  It is accredited by the National Assessment and Accreditation Council, with an ‘A’ Grade in 2017.[3] This accreditation status is valid for a period of five years.[4] Dibrugarh University is a member of the Association of Indian Universities (AIU)[5] and the Association of Commonwealth Universities (ACU).[6]
                  </p>
                  <p>Academics</p>
                  <p>Centre for Computer Studies</p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Administrative Office of the University</h5>
                  <p>
                  The Centre for Computer Studies (CCS) originates in a Computer Centre established in 1976, which taught a “Six-month Certificate Course on Computer Programming”. In 2004 it was upgraded to the Centre for Computer Studies and started “Post-Graduate Diploma in Computer Application (PGDCA)”. BCA was introduced in July 2004 and MCA and B.Sc IT in January and July 2007. The center has three computer laboratories as well as its own library.
                  </p>
                  <p>The MCA programme of CCS is approved by All India Council for Technical Education (AICTE). It is also an accredited study centre for Indira Gandhi National Open University.</p>
                  <p>Admission to CCS is based on a state level Common Admission Test (CAT). Candidates are then called for a personal interview, counseling and admission. In addition, some programmes have requirements for passing 10+2 level with mathematics and/or statistics at some threshold mark in aggregate.</p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Dibrugarh University Institute of Engineering and Technology</h5>
                  <p>
                  The Dibrugarh University Institute of Engineering and Technology (DUIET) was established in 2009 as a constituent institute and an integral part the university. The institute was established with due approval from the All India Council for Technical Education (AICTE), New Delhi and necessary permission from the Assam state government.
                  </p>
                  <p>The first session of the institute commenced in August 2009 with an intake capacity of 60 in each of the following disciplines to a B. Tech. degree:</p>
                  <ul>
                    <li>Electronics and Communication Engineering</li>
                    <li>Computer Science and Engineering</li>
                    <li>Petroleum Engineering</li>
                    <li>Mechanical Engineering</li>
                    <li>DUIET is located in the Dibrugarh University campus. It has excellent infrastructure and facilities. The administration of DUIET is headed by Dr. M.C.Bora who is the institute director.</li>
                  </ul>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Centre for Management Studies</h5>
                  <p>
                  The Centre for Management Studies (CMSDU) is a management school that is part of Dibrugarh University. It started functioning from 3 February 2003, with the first batch of Master of Business Administration (MBA) students enrolled into the two-year programme.[citation needed] The three-year Bachelor of Business Administration (BBA) and the three-year MBA (part-time) classes started in 2004.[citation needed] 2006 saw the launch of the one-year PGDTM (Post Graduate Diploma in Tourism Management) Programme.[citation needed] It is also running a Ph.D. programme which can be pursued both part-time as well as full-time.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Centre for Juridical Studies</h5>
                  <p>
                  The centre for juridical studies is a Centre of Dibrugarh University that imparts both undergraduate and Post Graduate legal education. The centre was established in the year 2006. The Centre runs BALL.B (H)and LL.M (Constitutional Law, Corporate Law and Criminal Law Groups) courses.[7]
                  </p>
                  <p>Eligibility: For B.A.LL.B. (H): A candidate for admission to the Five- year B.A.LL.B. (Hons) Course shall have passed the Higher Secondary Examination (10+2) or an equivalent examination securing at least 45% in aggregate of the total marks. Provided that there shall be a relaxation of 5% marks for SC/ST/OBC candidates. For LL.M.: A candidate to take admission into LL.M. Course must have 50% marks in aggregate in 3 year LL.B. or 5 year B.A.LL.B. (H) Course recognized by University Grants Commission (UGC) and Bar Council of India (BCI). The candidates appearing in final year examination of the aforesaid Programmes may also apply but at the time of admission they must produce the mark sheet and pass Certificate in original.</p>
                  <p>Admission Procedure: The admission to the aforesaid Programmes will be made only on merit basis through an Admission Test and (viva-voce/wherever applicable) to be conducted by the University. Admission cannot be claimed by any applicant as a matter of right. Note: In case of LL.M only, on being selected for admission, the applicant has to appear before admission committee at the Centre for Juridical Studies, Dibrugarh University with all original certificates, mark sheets and other requirements on scheduled date and time. If any selected candidate is unable to appear personally, he may send his authorized representative with the required documents. If any applicant fails to deposit prescribed fee within the stipulated time, his/her admission will automatically stand cancelled. If any selected candidate or his authorised representative does not appear before Admission Committee on scheduled date and time, his/her candidature shall be deemed to be cancelled without any intimation in writing.[7]</p>
                  <p>Dibrugarh University was ranked in the 101–150 band overall in India by the National Institutional Ranking Framework (NIRF) in 2019,[12] 86 among universities[13] and 35 in the pharmacy ranking.[11]</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoardDibrugarh;
