import axios from "axios";
import React, { useEffect, useState } from "react";
import htmlParser from "html-react-parser";
import { useLocation, useParams } from "react-router-dom";
import kid from "../../assets/images/kid.jpg";
import { Skeleton, Stack } from "@mui/material";

const AboutParticularInstitute = () => {

  const location= useParams()
  
  const [isLoading,setIsLoading]= useState(false)
  const [aboutParticularInstDesc, setAboutParticularInstDesc] = useState([]);
  const getAboutParticulaInstitute = async () => {
    setIsLoading(true)
    try {
      // const response = await axios.get('http://app.rollingnotes.in/backend/get_institute_description.php')
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_APP_API_URL}/backend/get_institute_description.php`
      );
     
      setAboutParticularInstDesc(response?.data?.data);
      setIsLoading(false)
    } catch (err) {
      console.log("err", err);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getAboutParticulaInstitute();
  }, []);
  return (
    <>
    
      <section className="college_intro">
        {/* <div style={{height:"300px"}}>
          <img src={kid} alt="kid" height={300} width={"100%"} />
        </div> */}
        <div className="container">
      
          <div className="row aboutInstitute">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                {isLoading ? (<>
      <div className="d-none d-md-block">

         <Stack spacing={4} width={1200}>
                      <Skeleton variant="rounded" height={30}/>
                      <Skeleton variant="rounded" height={25} />
                      <Skeleton variant="rounded" height={20} />
                      <Skeleton variant="rounded" height={20} />
                      <Skeleton variant="rounded" height={20} />
                    </Stack>
      </div>

      {/* Mobile Responsive */}

      <div className="d-md-none">
         <Stack spacing={4} width={300}>
                      <Skeleton variant="rounded" height={30}/>
                      <Skeleton variant="rounded" height={25} />
                      <Skeleton variant="rounded" height={20} />
                      <Skeleton variant="rounded" height={20} />
                      <Skeleton variant="rounded" height={20} />
                    </Stack>
      </div>
      </>
                )
                  :(
                  aboutParticularInstDesc?.map((item) => {
                    return (
                      <>
                  
                        {item?.institute_id &&
                          item?.institute_id === parseInt(location?.InstituteId) && (
                            <>
                              <h2 className="my-2">{item?.title}</h2>
                              <div className="content mt-5">
                                <p className="my-4">
                                  {htmlParser(item?.description_c)}
                                </p>
                              </div>
                            </>
                          )}
                      </>
                    );
                  })
                  
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutParticularInstitute;
