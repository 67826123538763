import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./StudyMaterial.css";
import "../Previous/Previous.css"
import AppContext from "../../feature/ContextForHeader/ContextForheader";
import { StudyMaterialsAdsModal } from "../../components/Modals/StudyMaterialsAdsModal";
import "../Previous/Previous.css";
import { AiOutlineConsoleSql, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { getCourse, getCourseData, getCourseTypes, getEbook, getInstituteData, getModule, getSemesterData, getSubjectData } from "../../feature/slices/StudyMaterialslice";
import PdfOption from "../Previous/PdfOption";
import { Skeleton } from "@mui/material";

export const StudyMaterial1 = () => {
  //Hooks
  const [optionForPdfData, setOptionForPdfData] = useState();
  const { stateforheader } = useContext(AppContext);
  const [nameOfModule, setNameOfModule] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [institutedata, setInstituteData] = useState();
  const [data, setData] = useState([]);
  const [coursedata, setCourseData] = useState();
  const [semesters, setSemesters] = useState();
  const [activeitem1, setactiveitem] = useState();
  const [activeitem2, setActiveItem2] = useState();
  const [subjects, setSubjects] = useState([]);
  const [books, setBooks] = useState([]);
  const [download, setDownload] = useState();
  const [modulename, setModuleName] = useState();
  const [activeitem3, setActiveItem3] = useState();
  const [courseTypeDashVal, setCourseTypeDashVal] = useState("");
  const [isDashCourseType, setIsDashCourseType] = useState(false);
  const [SelectedInstitute, setSelectedInstitute] = useState();
  const [semestername, setSemesterName] = useState();
  const [subjectname, setSubjectName] = useState();
  const [selectedCourseType,setSelectedCourseType] = useState();
  const [pdfLink, setPdfLink] = useState();
  const [active2, setActive2] = useState();
  const [institutename, setInstituteName] = useState();
  const [studymaterial, setStudyMaterial] = useState();

  const [moduleForDownload, setModuleForDownload] = useState();
  const [isvisible, setIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [coursetypeselectedsemester, setCourseTypeSelectedSemester] = useState(
    []
  );
  const dashLinkCourseType = useRef(null);
  const dashLinkCourses = useRef(null);
  const [adsVal, setAdsVal] = useState();
  const [subject, setSubject]= useState();
  const study= useSelector((state)=>state.study)
  const [isLoading, setIsLoading]= useState(false)

// params data

const {institute, module , semester, course, coursetype} = useParams()

  const dispatch = useDispatch();

  const iconstyle = {
    height: "25px",
    width: "25px",
  };

  useEffect(() => {
    setDownload("");
  }, [setBooks]);

  useEffect(() => {
    adsFun();
    getInstitute();
    window.scroll(0, 0);
    setSubjects("");
    setActiveItem2("");
    setBooks("");
    setDownload("");
  }, []);

  useEffect(() => {
    getInstitute();
    window.scroll(0, 0);
    setSubjects("");
    setActiveItem2("");
    setBooks("");
    setDownload("");
    // setSemesters("");
    setCourseData("");
    setactiveitem("");
  }, [stateforheader]);

  //ads function

  const adsFun = async (comesfrom, forpdf) => {

    try {
      const response = await axios.get(
        "https://rollingnotes.in/app/backend/api_v2/ads_setting.php"
      );
      if (response.status === 200) {
     
        let data = response?.data?.studymaterial;
        
        console.log(Object.keys(data))
        Object.keys(data).map((key) => {
          // console.log(key)
          if (comesfrom == key) {

            handleModal(data[key], forpdf);
          }
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  //Functions
  const getInstitute = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `https://rollingnotes.in/app/backend/api_v2/fetch_university.php?module=${study?.module_name}`
      );
      // console.log(response)
      if (response.status === 200) {
        let arr = [];
        for (let institute of response.data?.institute) {
          arr.push(institute);
        }
     
        setInstituteData(arr);
    setIsLoading(false)

      }
    } catch (err) {
      console.log(err);
    setIsLoading(false)

    }
  };

  // const getInstitute = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://rollingnotes.in/app/backend/api_v2/fetch_institutes.php"
  //     );
  //     if (response.status === 200) {
  //       let arr = [];
  //       for (let institute of response.data) {
  //         arr.push(institute);
  //       }
  //       setInstituteData(arr);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // console.log("study moduel",location.state)

  const getStudyMaterial = async (instituteselected) => {
    setIsLoading(true)

    const institute = instituteselected;
    const modulename = study?.module_name;
    setSelectedInstitute(instituteselected);
    setInstituteName(instituteselected);
    
    
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_course.php?module=${modulename}&institute=${institute}`,

        {
          headers: {
            "Content-Type": "application/json, text/plain",
          },
        }
      );

      // console.log(res)
      if (res.status === 200) {
        setData(res?.data?.courses);
        const data = res?.data?.courses;
        const filteredSemesters = res?.data?.courses
      
        setCourseData(res?.data?.courses);
    setIsLoading(false)
        
      }
    } catch (err) {
      console.log(err);
    setIsLoading(false)

    }
  };

 
  const getSemester = async (courseType) => {
    setIsLoading(true)
 
    const modulename = study?.module_name;
    setSelectedCourseType(courseType);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_semester.php?module=${modulename}&institute=${institutename}&course=${courseType}`,
        {
          headers: {
            "Content-Type": "application/json, text/plain",
          },
        }
      );
      // console.log("subject response",response)
      if (response.status === 200) {
        // setSubjects(response.data);
        setSemesters((response?.data?.semesters))
    setIsLoading(false)

      }
    } catch (err) {
      console.log(err);
    setIsLoading(false)

    }
  };

  const handleModal = (ads, forpdf) => {
    setModuleForDownload(forpdf);
    setShowModal(!showModal);
    setAdsVal(ads);
  };


  const getCourseType = async (semester) => {
    setIsLoading(true)

    const updatedSemester = semester?.replaceAll("*", "/");
    const data = {
      institute: institutename,
      course: selectedCourseType,
      semester: semester,
    };
    setSemesterName(semester);
    // setSelectedSemester(updatedSemester);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_coursetype.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        if (response.data[0]?.course_type === "-") {
          getSubjects("-");
          setCourseTypeDashVal(response?.data[0]?.course_type);
          setIsDashCourseType(true);
          // window.location.href =
          if (coursedata != "-") {
            dashLinkCourseType.current.click();
          } else {
            dashLinkCourseType.current.click();
          }
          setActive2(response?.data[0]?.course_type);
          // setPdfUrl("");
        } else {
          setIsDashCourseType(false);
          setCourseTypeSelectedSemester(response.data);
        }
      }
    setIsLoading(false)

    } catch (err) {
      console.log(err);
    setIsLoading(false)

    }
  };
  const getSubjects = async(courseType) => {
    setIsLoading(true)

    const modulename = study?.module_name;
    dispatch(getCourseTypes(courseType));
    dispatch(getCourseData(selectedCourseType));
    dispatch(getModule(modulename));
    dispatch(getInstituteData(institutename));
    dispatch(getSemesterData(semestername));

    
    const data = {
      institute: institute,
      course: course,
      semester: semestername,
      coursetype:courseType
    };

    // console.log("this is subject data",data)
    try {
      const response= await axios.post( `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_subjects.php`,
      data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      if(response.status==200){
        
        setSubjects(response?.data)
      }
    setIsLoading(false)

    } catch (error) {
      console.log(error)
    setIsLoading(false)

    }

  
   
   
  };

  const getPdfOption = async (subject) => {
    setIsLoading(true)

    // console.log("getPdfOptionApiCalled....")
    const data= study;
    const moduleName= study?.module_name;

    // console.log(data)
    try {
      const response = await axios.get(
        "https://app.rollingnotes.in/backend/get-content-pdf.php"
      );

        
      let pdfData = [];
      // console.log("data:::",data)

      response?.data?.data.map((pdfOptionValue) => {


        if (
          pdfOptionValue?.module_name === data.module_name &&
          pdfOptionValue?.subject_name === subject &&
          pdfOptionValue?.course_title === data.course_title &&
          pdfOptionValue?.semster === data.semster &&
          pdfOptionValue?.institute_title === data.institute_title &&
          pdfOptionValue?.coursetype === data.coursetype
        ) {
          
          pdfData.push({
            module_name: pdfOptionValue?.module_name,
            ebook_title: pdfOptionValue?.ebook_title,
            pdf_link: pdfOptionValue?.pdf_link,
          });
        }
      });

      // console.log("pdfData::", pdfData);
      setOptionForPdfData(pdfData);
      setNameOfModule(moduleName);
      setShowPdf(true);
    setIsLoading(false)

    } catch (err) {
      console.log("err", err);
    setIsLoading(false)

    }
  };
  
  const moduleName = study?.module_name;
  const getBooks = async (book) => {

    setSubjectName(book);
    const modulename = study?.module_name;
    
    setSubject(book)
    
    try {
     const response= await axios.get(`https://rollingnotes.in/app/backend/api_v2/fetch_content.php?module=${modulename}&institute=${institutename}&course=${selectedCourseType}&semster=${semestername}&subject_name=${book}`,{
        headers:{
          "Content-Type":"application/json, text/plain"
        },
      })
      if(response.status===200){
        setBooks(response?.data?.semster)
        // console.log(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  };

  // console.log(books)
  //JSX
  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>{study?.module_name}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <StudyMaterialsAdsModal
          show={showModal}
          close={handleModal}
          adsval={adsVal}
          state={
            moduleForDownload
              ? {
                  institute: institutename,
                  studymaterialname: studymaterial,
                  modulepdf: pdfLink,
                  semestername: semestername,
                  subject: subjectname,
                  course: coursedata,
                }
              : ""
          }
          fornavigate={moduleForDownload}
        />
      )}
      <div className="Institute-box py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="head-text text-center py-5">
                Select Your University Here:
              </p>
              {/* <div className="button_div"> */}
              <div className="row d-flex justify-content-around align-items-center">
              {isLoading ? (
                      <> 
                    <div className='d-flex justify-content-center flex-column d-none d-md-block align-items-center'>
                    <div className='d-flex mb-2  '>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                          variant="rounded"
                          width={140}
                          height={60}/>
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                           variant="rounded"
                           width={140}
                          height={65}/>
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                          variant="rounded"
                          width={140}
                          height={65}/>
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                          variant="rounded"
                          width={140}
                          height={65}/>
                       
                       
                     </div>
                   
                   
                     <div className='d-flex  mb-2'>
                   
                   
                     <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                      variant="rounded"
                      width={140}
                      height={65}/>
                             <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                      variant="rounded"
                      width={140}
                      height={65}/>
                             <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                      variant="rounded"
                      width={140}
                      height={65}/>
                             <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                      variant="rounded"
                      width={140}
                      height={65}/>
                             
                     </div>
                     </div>
                      {/* mobile loader */}
                     <div className = "d-md-none  ">
                        
                        <div className ="d-flex justify-content-center align-items-center col-12 m-2">
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       className="mx-2"
                          variant="rounded"
                          width={300}
                          height={65}/>
              
       
                          </div>
                        <div className ="d-flex justify-content-center align-items-center col-12 m-2"><Skeleton  className="mx-2" sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
                          variant="rounded"
                          width={300}
                          height={65}/>
                          
       
                          </div>
                        <div className ="d-flex justify-content-center align-items-center col-12 m-2">
                        <Skeleton  className="mx-2" sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
       variant="rounded"
       width={300}
       height={65}/>
       
     </div>
                       
                        <div className ="d-flex justify-content-center align-items-center col-12 m-2">
                        <Skeleton  className="mx-2" sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
       variant="rounded"
       width={300}
       height={65}/>
       
     </div>
                       
                        
                     


                      </div>
                     </>
                    ) :<>
                  
                {institutedata &&
                  institutedata?.map((item, ind) => {
                    return (
                      <div className="col-md-2 px-4 py-1">
                        <div className="button_div" key={ind}>
                          <button
                            className={`btn btn-primary ${
                              activeitem1 === item?.institute_title ||
                              location.state === activeitem1
                                ? "activeUni"
                                : ""
                            }`}
                            onClick={() => {
                              navigate(`/studymaterial/${study.module_name}/${item?.institute_title}`)
                              setactiveitem(item?.institute_title);
                              getStudyMaterial(item?.institute_title);
                              adsFun("Institute_name");
                              setActiveItem2("");
                              setSubjects("");
                              setBooks("");
                              setDownload("");
                              setActiveItem3("");
                              setSemesters("");
                            }}
                          >
                            <div className="button-inner">
                              {item?.institute_title}
                            </div>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  
                  </>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="Faq py-1 my-1">
        <div className="text-center py-5">{coursedata && coursedata?.map((data)=>{
          return (
            <button className="btn btn-primary mx-2" onClick={()=>{
              navigate(`/studymaterial/${study?.module_name}/${SelectedInstitute}/${data?.course_title}`)
              getSemester(data?.course_title)
              adsFun("subject_name")

              
            }}>
              {data?.course_title}
            </button>
          )
        })}</div>
        <div className="container">
          <div className="row">
            <div className="previous_pdf previous_one tabs_button d-flex justify-content-center">
              <div className="col-md-12">
                {semesters?.length > 0 && (
                  <p className="text-center py-5">
                    Select Your Semester or Class Below:
                  </p>
                )}
                <div
                  className="nav  nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                       {isLoading ? (
                      <> 
                    <div className='d-flex flex-column d-none d-md-block align-items-center'>
                    <div className='d-flex mb-2  '>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                          variant="rounded"
                          width={140}
                          height={65}/>
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                           variant="rounded"
                           width={140}
                          height={65}/>
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                           variant="rounded"
                           width={140}
                          height={65}/>
                         
                       
                       
                     </div>
                   
                   
                   
                     </div>
                      {/* mobile loader */}
                     <div className = "d-md-none  ">
                        
                        <div className ="d-flex col-12 m-2">
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       className="mx-2"
                          variant="rounded"
                          width={180}
                          height={65}/>
                        
       
                          </div>
                        <div className ="d-flex col-12 m-2"><Skeleton  className="mx-2" sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
                          variant="rounded"
                          width={180}
                          height={65}/>
                       
       
                          </div>
                        <div className ="d-flex col-12 m-2">
                        <Skeleton  className="mx-2" sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
       variant="rounded"
       width={180}
       height={65}/>
       
     </div>
                       
                        
                     


                      </div>
                     </>
                    ) : <>
                  
                  {semesters &&
                    semesters?.map((item, ind) => {
                      return (
                        <button
                          key={ind}
                          onClick={() => {
                            navigate(`/studymaterial/${study?.module_name}/${SelectedInstitute}/${course}/${item}`)
                            getCourseType(item);
                            adsFun("semester");
                            setActiveItem2(item);
                            setBooks("");
                            setDownload("");
                            setActiveItem3("");
                          }}
                          className={`nav-link ${
                            activeitem2 === item ? "active" : ""
                          }`}
                          id={`v-pills-${ind}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#v-pills-${ind}`}
                          type="button"
                          role="tab"
                          aria-controls={`v-pills-${ind}`}
                          aria-selected={
                            activeitem2 === item ? "true" : "false"
                          }
                        >
                          <i className="fa-regular fa-folder me-3"></i> {item}
                        </button>
                      );
                    })}
                  
                  </>}
                </div>
              </div>
              
              {semester 
                                     && (           
              <div className="col-md-12" id="semesterScroll">
                    <div className="button_div p-4 ml-5 d-flex justify-content-center align-items-center">
                      <div className="row d-flex justify-content-center">
                        {!isDashCourseType && ( 
                          <>
                            <div className="col-md-12">
                              <div className="d-flex justify-content-center mt-5 align-item-center">
                                <div>
                                  {coursetypeselectedsemester &&
                                    coursetypeselectedsemester?.length > 0 && (
                                      <p className="heading-semester text-center">
                                        Select Course Type:
                                      </p>
                                    )}

                                  {coursetypeselectedsemester &&
                                    coursetypeselectedsemester?.length > 0 &&
                                    coursetypeselectedsemester?.map(
                                      (item, ind) => {
                                        if (item?.course_type?.length > 0) {
                                          return (
                                            <>
                                             {/* hs */}
                                                  <button
                                                    key={ind}
                                                    onClick={() => {
                                                      navigate(`/studymaterial/${study?.module_name}/${SelectedInstitute}/${course}/${semestername}/${item.course_type}`)
                                                      getSubjects(
                                                        item.course_type
                                                      );
                                                      setActive2(
                                                        item.course_type
                                                      );
                                                      // setPdfUrl("");

                                                      adsFun(
                                                        "subject_name"
                                                      );
                                                    }}
                                                    className={` btn btn-primary mx-2 ${
                                                      active2 ===
                                                      item.course_type
                                                        ? "activecoursetype"
                                                        : ""
                                                    }`}
                                                  >
                                                    <div className="button-inner">
                                                      {item.course_type}
                                                    </div>
                                                  </button>
                                             
                                            </>
                                          );
                                        }
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            {/* <Link
  ref={dashLinkCourseType}
  to={`/${institute}/${InstituteId}/${courses}/${sem}/-`}
></Link>
                          */}
                          </>
                        ) }
                      </div>
                    </div>
                  </div>)}
            </div>

            <div className="col-md-12">
              <div className="py-4 text-center">
                {subjects && (
                  <p className="">
                    Please Click on Subject and Then Year to Download :{" "}
                  </p>
                )}
              </div>

              <div className="row">
              <div className="accordion" id="accordionExample">
              {isLoading ? (<>
                       <div className='d-flex d-none d-md-block flex-column align-items-center'>
                       <div className='mb-2 ms-5 me-5 '>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                   
                     </div>
                       <div className='d-flex d-md-none flex-column align-items-center'>
                       <div className='mb-2 ms-5 me-5 '>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                    
                     
                    
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                   
                     </div>
                     </>
                    ) :(
                  subjects &&
                    subjects?.map((item, ind) => {
                      // console.log("activeitem3 === item?.subject_name", activeitem3 === item?.subject_name)
                      return (
                        <div
                          key={ind}
                          className=" accordion-position"
                          id={`accordion${ind}`}
                        >
                          <div className="accordion-item">
                            <h2 className="accordion-header" id={`heading${ind}`}>
                              <button
                                className={`accordion-button study-accordion w-100 d-flex justify-content-between `}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${ind}`}
                                aria-expanded={
                                  activeitem3 === item?.subject_name
                                    ? "true"
                                    : "false"
                                }
                                aria-controls={`collapse${ind}`}
                                onClick={() => {
                                  dispatch(getSubjectData(item?.subject_name))
                                  if (activeitem3 === item?.subject_name) {
                                    setActiveItem3("");
                                  } else {
                                    setActiveItem3(item?.subject_name);
                                  }
                                  getPdfOption(item?.subject_name) 
                                  adsFun("book");
                                  // setActiveItem3(item?.subject_name);
                                  // setModuleName("");
                                  setDownload("");
                                }}
                              >
                                <div>{item?.subject_name}</div>
                                {activeitem3 === item?.subject_name ? (
                                  <>
                                    <AiOutlineMinus style={iconstyle} />
                                  </>
                                ) : (
                                  <>
                                    <AiOutlinePlus style={iconstyle} />
                                  </>
                                )}
                              </button>
                            </h2>
                            <div id={`collapse${ind}x`} className={`accordion-collapse collapse ${
                                  activeitem3 === item?.subject_name
                                  ? "show"
                                  : ""
                                }`} aria-labelledby={``} data-bs-parent="#accordionExample">
      <div className="accordion-body">
            {showPdf ? (
              <section className="my-4">
                <PdfOption
               
                  instituteName={study.institute_title}
                  optionForPdfData={optionForPdfData}
                  nameOfModule={moduleName}
                  selectedcourse={study.course_title}
                  bookname={study.subject_name}
                  selectedcoursetype={study.coursetype}
                  semesterselected={semestername}
                />
              </section>
            ) : (
          ""
            )}
          </div>
          </div>

                          </div>
                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyMaterial1;
