import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import AdsModalForInstitute from "../../components/Modals/AdsModalForInstitute";
import { Link, useLocation, useParams } from "react-router-dom";
import "./Previous.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
function PdfOption({
  id,
  nameOfModule,
  optionForPdfData,
  selectedcourse,
  bookname,
  semesterselected,
  selectedcoursetype,
  instituteName,
}) {
  const [modulefordownload, setModuleForDownload] = useState();
  const [showModal, setShowModal] = useState(false);
  const [adsvalue, setAdsVal] = useState("");
  const [time, setTime] = useState(0);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(false);
  const [selectedPdfLink, setSelectedPdfLink] = useState("");
  const [selectedPdfName, setSelectedPdfName] = useState("");
  const { institute,InstituteId , courses, sem, courseType, subject, modulee } = useParams();
  const iconstyle = {
    height: "25px",
    width: "25px",
  };
  let updatedSemester = sem ? sem.replaceAll("*", "/") : sem;
  const getAdsValue = async (comesfrom, forpdf) => {
    try {
      const res = await axios.get(
        "https://rollingnotes.in/app/backend/api_v2/ads_setting.php"  
      );
      setModuleForDownload(forpdf);
      setShowModal(!showModal);
      if (res.status === 200) {
        const data = res?.data?.institute;

        Object.keys(data).map((key) => {
          if (key === comesfrom[0]) {
            handleModal(data[key], forpdf);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleModal = (ads, forpdf) => {
    setAdsVal(ads);
  };
  console.log(instituteName, selectedcourse, semesterselected, selectedcoursetype, bookname, nameOfModule);
  return (
    <>
      {/* <h1>Hello World</h1> */}
   
      <div className="container" id="test2">
      {instituteName  && selectedcourse && semesterselected && selectedcoursetype && bookname && nameOfModule  && (
        <div>
          <div className="accordion accordion-module" id={`accordion`}>
            <div className="accordion-item ">

                <>
                   <div class="row">
                <div class="first_year_acc text-center d-block">
                  <div class="advanced-heading-content">
                    <div class="">{updatedSemester}</div>
                  </div>
                  <div class="sub-heading">
                    <div class="sub-heading-content">
                      {optionForPdfData?.length > 0 ? `Previous Year Question Papers for ${institute}` : "No study material for this course type or semester" }
                      
                    </div>
                  </div>
                  <h2 class="heading-title">
                    <div class="main-heading">
                      <div class="main-heading-inner">{subject}</div>
                    </div>
                  </h2>
                </div>
              </div>
                </>
            
           

              <div
                id={`collapse`}
                className={`accordion-collapse show collapse text-center `}
                // style={{textAlign:"center"}}
                aria-labelledby={`heading`}
                data-bs-parent={`#accordion`}
              >
                {Array.isArray(optionForPdfData) &&
                  optionForPdfData?.length > 0 &&
                  optionForPdfData?.map((item) => {
                    console.log(item)
                    const sems= semesterselected?.replaceAll("/", "*")
                    return (
                      <> 
                        <Link
                          to={InstituteId ? `/${institute}/${InstituteId}/${courses}/${sem}/${courseType}/${subject}/${modulee}/${item?.ebook_title}` : `/${instituteName}/1/${selectedcourse}/${sems}/${selectedcoursetype}/${bookname}/${nameOfModule}/${item?.ebook_title}`}
                        >
                          <button
                            className="module-btn p-2 m-2"
                            onClick={() => {
                              getAdsValue(Object.keys(item), "From-pdflink");
                              setSelectedPdfName(item?.ebook_title);
                              
                              setSelectedPdfLink(item?.pdf_link);
                              // console.log("hello", item?.pdf_link);
                            }}
                          >
                            {item?.ebook_title } 
                            
                            {/* <span >{item?.pdf_link}</span> */}
                          </button>
                        </Link>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
      </div>

      {showModal && (
        <AdsModalForInstitute
          setTime={setTime}
          show={showModal}
          state={
            modulefordownload?.length > 0
              ? {
                  institute: institute,
                  course: selectedcourse,
                  coursetype: selectedcoursetype,
                  bookname: bookname,
                  semester: semesterselected,
                  module: nameOfModule,
                }
              : ""
          }
          close={handleModal}
          adsval={adsvalue}
          fornavigate={modulefordownload}
          subjectName={bookname}
          selectedPdfName={selectedPdfName}
          selectedPdfLink={selectedPdfLink}
          // renderingstate={renderingstate}
          // updateParentState={updateParentState}
        />
      )}
    </>
  );
}

export default PdfOption;
