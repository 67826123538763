import React from "react";
import Accordion from "react-bootstrap/Accordion";
const Newcomponent = () => {
  return (
    <>
      <div className="container">
        <Accordion >
          <Accordion.Item eventKey="0" className="mt-2 border border-bottom">
            <Accordion.Header >Pdf 1</Accordion.Header>
            <Accordion.Body >
              <button className="btn btn-primary m-2">Pdf 1</button>
              <button className="btn btn-primary m-2">Pdf 2</button>
              <button className="btn btn-primary m-2">Pdf 3</button>
              <button className="btn btn-primary m-2">Pdf 4</button>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className="mt-2 ">
            <Accordion.Header>Pdf 2</Accordion.Header>
            <Accordion.Body>
              <button className="btn btn-primary m-2">Pdf 1</button>
              <button className="btn btn-primary m-2">Pdf 2</button>
              <button className="btn btn-primary m-2">Pdf 3</button>
              <button className="btn btn-primary m-2">Pdf 4</button>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="mt-2 ">
            <Accordion.Header>Pdf 3</Accordion.Header>
            <Accordion.Body>
              <button className="btn btn-primary m-2">Pdf 1</button>
              <button className="btn btn-primary m-2">Pdf 2</button>
              <button className="btn btn-primary m-2">Pdf 3</button>
              <button className="btn btn-primary m-2">Pdf 4</button>
            </Accordion.Body>
          </Accordion.Item>

        </Accordion>
      </div>
    </>
  );
};

export default Newcomponent;
