import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchTimerVal = createAsyncThunk("/timer/getTimerVal" , async(payload , {rejectWithValue})=>{
    try{
        let {data} = await axios.get("https://app.rollingnotes.in/backend/get_ads_timing.php")
        return data?.data

    }
    catch(error){
        console.log("error" , error)
        return rejectWithValue(error)
    }
})