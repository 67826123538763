import React from 'react';
import "../Study material/StudyMaterial.css"
import { useLocation } from 'react-router-dom';


const HeaderBanner = ({state}) => {
    
  return (

    <>
    <section className={`sticky-banner `}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-box">
              <div className="ahsec2-heding">
                  <p className="sticky-text text-light">
                    {" "}
                    {state?.bookname}- {state?.course} |{" "}
                    {state?.semester} - | {state?.institute}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeaderBanner;