import { createSlice } from "@reduxjs/toolkit";


const studyMaterialslice = createSlice({
    name:"study",
    initialState:{
        institute_title:"",
        module_name:"",
        semster:"",
        subject_name:"",
        course_title:"",
        coursetype:""

    },
    reducers:{
        getInstituteData:(state,action)=>{
            state.institute_title= action.payload;
        },
        getModule:(state,action)=>{
            state.module_name= action.payload;
        },
        getSemesterData:(state,action)=>{
            state.semster= action.payload;
        },
        getSubjectData:(state,action)=>{
            state.subject_name= action.payload;
        },
        getCourseData:(state,action)=>{
            state.course_title= action.payload;
        },
        getCourseTypes:(state,action)=>{
            state.coursetype= action.payload;
        },
    }
})

export const  {getCourseData, getInstituteData,getSemesterData, getSubjectData, getModule,getCourseTypes} = studyMaterialslice.actions;
export default studyMaterialslice.reducer;