import React from "react";

const AccordionItems = ({ faq, onToggle, active }) => {
  const { question, answar } = faq;

  return (
    <>
      <div className="home_accordion">
        <div className="accordion-item">
          <h1 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              onClick={onToggle}
            >
              {question}
              <span className="control">{active ? "—" : "+"} </span>
            </button>
          </h1>
          {active ? (
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>{answar}</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default AccordionItems;
