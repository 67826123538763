import React, { useRef, useEffect, useState } from "react";
import "./Previous.css";
import { useLocation, Link, useParams } from "react-router-dom";
import AppContext from "../../feature/ContextForHeader/ContextForheader";
import { useContext } from "react";

import axios from "axios";
import qs from "qs";

import AdsModalForInstitute from "../../components/Modals/AdsModalForInstitute";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import AboutParticularInstitute from "./AboutParticularInstitute";
import PdfOption from "./PdfOption";
import {
  Link as ScrollLink,
 animateScroll as scroll,
} from "react-scroll";

import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";


const Institute = () => {

  const { stateforheader } = useContext(AppContext);



  const { institute, InstituteId, courses, sem, courseType, subject, modulee } =
    useParams();

  const [course, setCourse] = useState([]);
  const [coursenameheading, setCourseNameHeading] = useState();
  const [semester, setSemester] = useState([]);
  const [selectedcoursesemester, setSelectedCourseSemester] = useState([]);
  const [coursetypeselectedsemester, setCourseTypeSelectedSemester] = useState(
    []
  );

  const [semesterselected, setSelectedSemester] = useState();
  const [subjects, setSubjects] = useState([]);
  const [selectedcoursetype, setSelectedCourseType] = useState();
  const [active1, setActive1] = useState();
  const [active2, setActive2] = useState();
  const [activeitem2, setActiveItem2] = useState();
  const [modules, setModules] = useState();
  const [activeitem3, setActiveItem3] = useState();
  const [bookname, setBookName] = useState();
  const [pdfUrl, setPdfUrl] = useState("");
  const [module, setModule] = useState();
  const [selectedcourse, setSelectedCourse] = useState();
  const [showModal, setShowModal] = useState(false);
  const [adsvalue, setAdsVal] = useState();
  const [semestername, setSemesterName] = useState();
  const coursedata = useRef();
  const semsterdataclick = useRef();
  const [adscomesfrom, setAdsComesFrom] = useState();
  const [modulefordownload, setModuleForDownload] = useState();
  const [modulename, setModuleName] = useState();
  const [time, setTime] = useState(0);
  const [IsDashVal, setIsDashVal] = useState(false);
  const [pdfOption, setPdfOption] = useState([]);
  const [optionForPdfData, setOptionForPdfData] = useState();
  const [nameOfModule, setNameOfModule] = useState("");
  const [isDashCourseType, setIsDashCourseType] = useState(false);
  const [courseTypeDashVal, setCourseTypeDashVal] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const dashLinkCourseType = useRef(null);
  const dashLinkCourses = useRef(null);
  const [isLoading, setIsLoading]= useState(false)

  const iconstyle = {
    height: "25px",
    width: "25px",
  };

  // console.log("modulee", modulee);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get semester course wise
        const semesterCourseWiseData = await getSemesterCourseWise(courses);
        console.log(semesterCourseWiseData);

        // Get course type
        const courseTypeData = await getCourseType(sem);
        console.log(courseTypeData);

        // Get subjects based on course type
        const subjectsData = await getSubjects(courseType);
        console.log(subjectsData);

        // Get modules based on subjects
        const modulesData = await getModules(subject);
        console.log(modulesData);

        // Get PDF options based on module
        const updatedSemester = sem ? sem?.replaceAll("*", "/") : sem;
        let allData = {
          institute_title: institute,
          coursetype: courseType,
          module_name: modulee,
          semster: updatedSemester,
          subject_name: subject,
          course_title: courses,
        };
        const pdfOptionsData = await getPdfOption(allData, modulee);
        console.log(pdfOptionsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Include dependencies as needed
  //useeffect hook

  useEffect(() => {
    getAllCources();
    // window.scroll(0, 0);
    setSelectedCourseSemester("");
    setSubjects("");
    setActiveItem2("");
    setCourseNameHeading("");
  }, []);

  useEffect(() => {
    getAllCources();
    setCourseTypeSelectedSemester("");
    setSubjects("");
    setModules("");
    setActiveItem2("");
    setActive1("");
    setCourseNameHeading("");
    setSelectedCourseSemester("");
    // window.scroll(0, 0);
  }, [stateforheader]);

  useEffect(() => {
    getSubjects(courseTypeDashVal);
  }, [courseTypeDashVal]);
  // All functions Functions

  //------------------------------------------------- handle modals------------------------------------------------
  const handleModal = (ads, forpdf) => {
    setModuleForDownload(forpdf);
    setShowModal(!showModal);

    setAdsVal(ads);
  };
  //------------------------------------------------- get ads value---------------------------------------------------
  const getAdsValue = async (comesfrom, forpdf) => {
    try {
      const res = await axios.get(
        "https://rollingnotes.in/app/backend/api_v2/ads_setting.php"
      );
      if (res.status === 200) {
        const data = res?.data?.institute;

        Object.keys(data).map((key) => {
          if (key === comesfrom[0]) {
            handleModal(data[key], forpdf);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  //------------------------------------------------- get all courses-------------------------------------------------
  const getAllCources = async () => {
    setIsLoading(true)
    const postdata = {
      institute: institute,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_courses.php`,
        qs.stringify(postdata),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        console.log("course", response)
        postdata.course = response?.data[0]?.course_title;
        if (response.data[0]?.course_title === "-") {
          setIsDashVal(true);
          setCourse(response.data);
          setCourseNameHeading(response.data[0]?.course_title);
          getSemesterCourseWise(response.data[0]?.course_title);
          if (courses != "-") {
            dashLinkCourses.current.click();
          }
          getAdsValue(response.data[0]);
          setSemester("");
          setSubjects("");
          setCourseTypeSelectedSemester("");
          setActive1(response.data[0]?.course_title);
          setActive2("");
          setActiveItem2("");
        }

        setCourse(response.data);

        getSemesters(postdata);
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  };

  // -----------------scroll for pdf option-----------------------------------
  const scrollTo = () => {
    scroll.scrollTo(100); // Scrolling to 100px from the top of the page.
  };
  //------------------------------------------------- get semester----------------------------------------------------
  const getSemesters = async (data) => {
    setIsLoading(true)
    try {
       const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_semesters.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        setSemester(response.data);
      }
      setIsLoading(false)
    } catch (err) {
      console.log(err);
      setIsLoading(false)
    }
  };
  //------------------------------------ get semster according to course wise------------------------------------------
  const getSemesterCourseWise = async (courses) => {
    const data = {
      institute: institute,
      course: courses,
    };
    setSelectedCourse(courses);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_semesters.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        setSelectedCourseSemester(response.data);
        setIsLoading(false)
      }
    } catch (err) {
      console.log(err);
    }
  };
  //-------------------------------------------------get course type---------------------------------------------------
  const getCourseType = async (semester) => {
    const updatedSemester = semester?.replaceAll("*", "/");
    const data = {
      institute: institute,
      course: courses,
      semester: updatedSemester,
    };

    setSelectedSemester(updatedSemester);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_coursetype.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        if (response.data[0]?.course_type === "-") {
          getSubjects(response?.data[0]?.course_type);
          setCourseTypeDashVal(response?.data[0]?.course_type);
          setIsDashCourseType(true);
          setIsLoading(false)
          // window.location.href =
          if (courseType != "-") {
            dashLinkCourseType.current.click();
          } else {
            dashLinkCourseType.current.click();
          }
          setActive2(response?.data[0]?.course_type);
          setPdfUrl("");
        } else {
          setIsDashCourseType(false);
          setCourseTypeSelectedSemester(response.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  //----------------------------------------------------get subjects----------------------------------------------------
  const getSubjects = async (courseType) => {
    const updatedSemester = sem?.replaceAll("*", "/");
    const data = {
      institute: institute,
      course: courses,
      semester: updatedSemester,
      coursetype: courseType,
    };

    setSelectedCourseType(courseType);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_subjects.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log("subject response",response)
      if (response.status === 200) {
        setSubjects(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  //----------------------get modules(subject sub part like as chapter ) according to the subject ----------------------
  const getModules = async (subject) => {
    const updatedSemester = sem?.replaceAll("*", "/");
    const data = {
      institute: institute,
      course: courses,
      semester: updatedSemester,
      coursetype: courseType,
      subject: subject,
    };
    setBookName(subject);
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_modules.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        setModules(response?.data);
        // console.log("this isis sisiis <", response?.data)
      }
      setIsLoading(false)
    } catch (err) {
      console.log(err);
      setIsLoading(false)
    }
  };

  const scrollMore = () => {
    scroll.scrollMore(100); // Scrolling an additional 100px from the current scroll position.
  };
  //----------------------------------------------------get pdfOption--------------------------------------------------
  const getPdfOption = async (data, moduleName) => {
    // console.log("getPdfOptionApiCalled....")
    try {
      const response = await axios.get(
        "https://app.rollingnotes.in/backend/get-content-pdf.php"
      );

      let pdfData = [];
      // console.log("data:::",data)

      response?.data?.data.map((pdfOptionValue) => {
        if (
          pdfOptionValue?.module_name === data.module_name &&
          pdfOptionValue?.subject_name === data.subject_name &&
          pdfOptionValue?.course_title === data.course_title &&
          pdfOptionValue?.semster === data.semster &&
          pdfOptionValue?.institute_title === data.institute_title &&
          pdfOptionValue?.coursetype === data.coursetype
        ) {
          console.log("everything is matched")
          pdfData.push({
            module_name: pdfOptionValue?.module_name,
            ebook_title: pdfOptionValue?.ebook_title,
            pdf_link: pdfOptionValue?.pdf_link,
          });
        }
      });

      console.log("pdfData::", pdfData);
      setOptionForPdfData(pdfData);
      setNameOfModule(moduleName);

      setShowPdf(true);
    } catch (err) {
      console.log("err", err);
    }
  };
  //-------------------------------------------close function when modal close--------------------------------------------
  let functionForClose = (val1, val2, val3, val4, val5) => {
    if (val1) {
      setSelectedCourseSemester([]);
    }
    if (val2) {
      setSelectedCourseSemester(selectedcoursesemester);
      setCourseTypeSelectedSemester([]);
    }
    if ((val3 && val4) || val3) {
      setCourseTypeSelectedSemester(coursetypeselectedsemester);
      setSelectedCourseSemester(selectedcoursesemester);
      setSubjects([]);
      setShowPdf(false);
    }
  };

  return (
    <>
      {institute && courses && sem && courseType && subject && modulee ? (
        <Helmet>
          <title>{`${institute}/${courses}/${sem}/${courseType}/${subject}/${modulee}`}</title>
          <meta
            name="content"
            description={`${institute} ${courses} ${sem} ${courseType} ${subject} ${modulee}`}
          />
        </Helmet>
      ) : institute && courses && sem && courseType && subject ? (
        <Helmet>
          <title>{`${institute}/${courses}/${sem}/${courseType}/${subject}}`}</title>
          <meta
            name="content"
            description={`${institute} ${courses} ${sem} ${courseType} ${subject}`}
          />
        </Helmet>
      ) : institute && courses && sem && courseType ? (
        <Helmet>
          <title>{`${institute}/${courses}/${sem}/${courseType}`}</title>
          <meta
            name="content"
            description={`${institute} ${courses} ${sem} ${courseType}`}
          />
        </Helmet>
      ) : institute && courses && sem ? (
        <Helmet>
          <title>{`${institute}/${courses}/${sem}}`}</title>
          <meta name="content" description={`${institute} ${courses} ${sem}`} />
        </Helmet>
      ) : institute && courses ? (
        <Helmet>
          <title>{`${institute}/${courses}}`}</title>
          <meta name="content" description={`${institute} ${courses} `} />
        </Helmet>
      ) : (
        institute && (
          <Helmet>
            <title>{`${institute}}`}</title>
            <meta name="content" description={`${institute}`} />
          </Helmet>
        )
      )}

      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>{institute}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="first_main 12">
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="first_year_info text-center">
                  {isLoading ? (<div></div>):(
                     !IsDashVal && (
                      <>
                        <p className="py-5">Select Your Course Here :</p>
                        <div className="button_div">
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {course && course?.length > 0 ? (
                              course?.map((key, index) => {
                                return (
                                  <>
                                    <div
                                      className="col-md-3 "
                                      // ref={coursedata}
                                      key={index}
                                    >
                                      <ScrollLink
                                        activeClass="active"
                                        to="coursesScroll"
                                        spy={true}
                                        smooth={true}
                                        offset={50}
                                        duration={500}
                                      
                                      >
                                        <Link
                                          to={`/${institute}/${InstituteId}/${key.course_title}`}
                                        >
                                          <button
                                            onClick={() => {
                                              setCourseNameHeading(
                                                key.course_title
                                              );
                                              getSemesterCourseWise(
                                                key.course_title
                                              );
                                                setIsLoading(true)
                                              getAdsValue(Object.keys(key));
                                              setSemester("");
                                              setSubjects("");
                                              setCourseTypeSelectedSemester("");
  
                                              setActive1(key.course_title);
                                              setActive2("");
                                              setActiveItem2("");
                                            }}
                                            className={`btn btn-primary mx-2 ${
                                              active1 === key.course_title
                                                ? "activecourse"
                                                : ""
                                            }`}
                                          >
                                            <div className="button-inner">
                                              {key.course_title}
                                            </div>
                                          </button>
                                        </Link>
                                      </ScrollLink>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <Link
                                ref={dashLinkCourses}
                                to={`/${institute}/${InstituteId}/-`}
                              ></Link>
                            )}
                          </div>
                        </div>
                      </>
                    )
                  )}
                 
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="first_year_acc text-center d-block">
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">
                      {!IsDashVal && coursenameheading}
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="Faq py-1 my-5" id="coursesScroll">
          <div className="container">
          
            <div className="row my-4">
            
              
              {selectedcoursesemester && selectedcoursesemester?.length > 0 ? (
                <p className="heading-semester text-center">
                   Select Your Semester or Class Below:
                </p> 
              ) : (
                ""
              )}
              

              <div className="previous_pdf previous_one tabs_button d-flex">
                <div className="col-md-12">
                  <div
                    className="button_div "
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <>
                    
                    {isLoading ? (
                      <> 
                    <div className='d-flex flex-column d-none d-md-block align-items-center'>
                    <div className='d-flex mb-2  '>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                          variant="rounded"
                          width={140}
                          height={60}/>
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                           variant="rounded"
                           width={140}
                          height={65}/>
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                          variant="rounded"
                          width={140}
                          height={65}/>
                       
                       
                     </div>
                   
                   
                     <div className='d-flex  mb-2'>
                   
                   
                     <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                      variant="rounded"
                      width={140}
                      height={65}/>
                             <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                      variant="rounded"
                      width={140}
                      height={65}/>
                             <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',margin: '0 80px' }}
       
                      variant="rounded"
                      width={140}
                      height={65}/>
                             
                     </div>
                     </div>
                      {/* mobile loader */}
                     <div className = "d-md-none  ">
                        
                        <div className ="d-flex col-12 m-2">
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       className="mx-2"
                          variant="rounded"
                          width={140}
                          height={65}/>
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
       variant="rounded"
       width={140}
       height={65}/>
       
                          </div>
                        <div className ="d-flex col-12 m-2"><Skeleton  className="mx-2" sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
                          variant="rounded"
                          width={140}
                          height={65}/>
                          <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
       variant="rounded"
       width={140}
       height={65}/>
       
                          </div>
                        <div className ="d-flex col-12 m-2">
                        <Skeleton  className="mx-2" sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
       variant="rounded"
       width={140}
       height={65}/>
       
       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px',}}
       
                          variant="rounded"
                          width={140}
                          height={65}/></div>
                       
                        
                     


                      </div>
                     </>
                    ) :<>
                  {selectedcoursesemester &&
                        selectedcoursesemester.map((semester, ind) => {
                          let sem = semester?.semester?.replaceAll("/", "*");

                          return (
                          
                          <>
                        <div
                          className="col-md-3 text-center "
                          // ref={semsterdataclick}
                          key={ind}
                        >
                          <ScrollLink
                            activeClass="active"
                            to="semesterScroll"
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={500}
                           
                          >
                            <Link
                              to={`/${institute}/${InstituteId}/${courses}/${sem}`}
                            >
                              <button
                                // key={ind}
                                className={`btn btn-primary mx-2 ${
                                  activeitem2 === semester.semester
                                    ? "active_btn"
                                    : ""
                                }`}
                                onClick={() => {
                                  getCourseType(semester.semester);
                             
                                  getAdsValue(Object.keys(semester));

                                  setSemesterName(semester.semester);
                                  setSemester("");
                                  setSubjects("");
                                  setActive2("");
                                  setPdfUrl("");
                                  setActiveItem2(semester.semester);
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  className="button-inner"
                                >
                                  {semester.semester} 
                                </div>
                              </button>
                            </Link>
                          </ScrollLink>
                        </div>
                        <Link
                          ref={dashLinkCourseType}
                          to={`/${institute}/${InstituteId}/${courses}/${sem}/-`}
                        ></Link>
                      
                      </>
                          
                          );
                          
                        })}
                  </>}
                  
                    </>
                  </div>

                  <div className="col-md-12" id="semesterScroll">
                    <div className="button_div p-4 ml-5 d-flex justify-content-center align-items-center">
                      <div className="row d-flex justify-content-center">
                        {!isDashCourseType && (
                          <>
                            <div className="col-md-12">
                              <div className="d-flex justify-content-center mt-5 align-item-center">
                                <div>
                                  {coursetypeselectedsemester &&
                                    coursetypeselectedsemester?.length > 0 && (
                                      <p className="heading-semester text-center">
                                        Select Course Type:
                                      </p>
                                    )}

                                  {coursetypeselectedsemester &&
                                    coursetypeselectedsemester?.length > 0 &&
                                    coursetypeselectedsemester?.map(
                                      (item, ind) => {
                                        if (item?.course_type?.length > 0) {
                                          return (
                                            <>
                                              <ScrollLink
                                                activeClass="active"
                                                to="courseTypeScroll"
                                                spy={true}
                                                smooth={true}
                                                offset={50}
                                                duration={500}
                                              
                                              >
                                                <Link
                                                  to={`/${institute}/${InstituteId}/${courses}/${sem}/${item.course_type}`}
                                                >
                                                  <button
                                                    key={ind}
                                                    onClick={() => {
                                                      getSubjects(
                                                        item.course_type
                                                      );
                                                      setActive2(
                                                        item.course_type
                                                      );
                                                      setPdfUrl("");

                                                      getAdsValue(
                                                        Object.keys(item)
                                                      );
                                                    }}
                                                    className={` btn btn-primary mx-2 ${
                                                      active2 ===
                                                      item.course_type
                                                        ? "activecoursetype"
                                                        : ""
                                                    }`}
                                                  >
                                                    <div className="button-inner">
                                                      {item.course_type}
                                                    </div>
                                                  </button>
                                                </Link>
                                              </ScrollLink>
                                            </>
                                          );
                                        }
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                      
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="col-md-12" id="courseTypeScroll">
                      <div className="p-4 study-section">

                      {isLoading ? (<>
                       <div className='d-flex d-none d-md-block flex-column align-items-center'>
                       <div className='mb-2 ms-5 me-5 '>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={1200}
       height={50}/>
      
                     </div>
                   
                     </div>
                       <div className='d-flex d-md-none flex-column align-items-center'>
                       <div className='mb-2 ms-5 me-5 '>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                    
                     
                    
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                       <div className='mb-2 ms-5 me-5'>
                       <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={305}
       height={50}/>
      
                     </div>
                   
                     </div>
                     </>
                    ) :(
                        subjects &&
                          subjects?.length > 0 &&
                          subjects?.map((item, ind) => {
                            return (
                              <div
                                key={ind}
                                className="accordion accordion-module"
                                id={`accordion${ind}`}
                              >
                                <div className="accordion-item ">
                                  <h2
                                    className="accordion-header"
                                    id={`heading${ind}`}
                                  >
                                    <ScrollLink
                                      activeClass="active"
                                      to="subjectScroll"
                                      spy={true}
                                      smooth={true}
                                      offset={50}
                                      duration={500}
                                 
                                    >
                                      <Link
                                        to={`/${institute}/${InstituteId}/${courses}/${sem}/${courseType}/${item?.subject_name}`}
                                      >
                                        <button
                                          className={`accordion-button study-accordion  w-100 d-flex justify-content-between`}
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#collapse${ind}`}
                                          aria-expanded={
                                            activeitem3 === item.subject_name
                                              ? "true"
                                              : "false"
                                          }
                                          onClick={() => {
                                            if (
                                              activeitem3 === item.subject_name
                                            ) {
                                              setActiveItem3("");
                                            } else {
                                              setActiveItem3(item.subject_name);
                                            }
                                            getModules(item.subject_name);
                                            setPdfUrl("");
                                            getAdsValue(Object.keys(item));
                                          }}
                                        >
                                          <div>{item.subject_name}</div>{" "}
                                          {activeitem3 === item.subject_name ? (
                                            <>
                                              <AiOutlineMinus
                                                style={iconstyle}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <AiOutlinePlus
                                                style={iconstyle}
                                              />
                                            </>
                                          )}
                                        </button>
                                      </Link>
                                    </ScrollLink>
                                  </h2>
                                  <div
                                    id={`collapse${ind}`}
                                    className={`accordion-collapse collapse ${
                                      activeitem3 === item.subject_name
                                        ? "show"
                                        : ""
                                    }`}
                                    aria-labelledby={`heading${ind}`}
                                    data-bs-parent={`#accordion${ind}`}
                                  >
                                    <div className="card-body d-flex flex-wrap shadow-for-moduleName">
                                      {modules &&
                                        modules?.map((item, ind1) => {
                                          return (
                                            <ScrollLink
                                              activeClass="active"
                                              to="test2"
                                              spy={true}
                                              smooth={true}
                                              offset={50}
                                              duration={500}
                                              onClick={() => {
                                                getAdsValue(
                                                  Object.keys(item),
                                                  "subject"
                                                );
                                              }}
                                            >
                                              <div
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${ind}`}
                                                aria-expanded={
                                                  activeitem3 ===
                                                  item.subject_name
                                                    ? "true"
                                                    : "false"
                                                }
                                              >
                                                <Link
                                                  to={`/${institute}/${InstituteId}/${courses}/${sem}/${courseType}/${subject}/${item?.module_name}`}
                                                >{isLoading ? (
                                                  <div>
    <Skeleton sx={{ bgcolor: 'grey.300' , borderRadius: '5px', }}
       
       variant="rounded"
       width={120}
       height={40}/>  
                                                  </div>
                                                ):(
                                                  <div
                                                  key={ind1}
                                                  className="module-btn mx-3 my-2 btn "
                                                  onClick={() => {
                                                    
                                                    getAdsValue([
                                                      "module_name",
                                                    ]);
                                                    setModuleName(
                                                      item.module_name
                                                    );

                                                    setPdfOption(item);

                                                    getPdfOption(
                                                      item,
                                                      item.module_name
                                                    );
                                                  }}
                                                >
                                                  {item.module_name}
                                                </div>
                                                ) }
                                                
                                                </Link>
                                              </div>
                                            </ScrollLink>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                    )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        {showModal && (
          <AdsModalForInstitute
            setTime={setTime}
            show={showModal}
            selectedcoursesemester={selectedcoursesemester}
            coursetypeselectedsemester={coursetypeselectedsemester}
            subjects={subjects}
            optionForPdfData={optionForPdfData}
            state={
              modulefordownload?.length > 0
                ? {
                    institute: institute,
                    course: selectedcourse,
                    coursetype: selectedcoursetype,
                    bookname: bookname,
                    semester: semesterselected,
                    module: modulename,
                  }
                : ""
            }
            close={handleModal}
            adsval={adsvalue}
            fornavigate={modulefordownload}
            functionForClose={functionForClose}
          />
        )}
      </div>

 
        <div>
          {console.log(` institueName=${institute}
                optionForPdfData=${optionForPdfData}
                nameOfModule=${module}
                selectedcourse=${courses}
                bookname=${subject}
                selectedcoursetype=${courseType}
                semesterselected=${sem}`)}
          {showPdf ? (
            <section className="my-4">
              <PdfOption
             
                instituteName={institute}
                optionForPdfData={optionForPdfData}
                nameOfModule={modulename}
                selectedcourse={courses}
                bookname={subject}
                selectedcoursetype={courseType}
                semesterselected={sem}
              />
            </section>
          ) : (
            ""
          )}
        </div>
  
      <section className="d-flex justify-content-center mb-3">
      <AboutParticularInstitute/>
      </section>

    </>
  );
};

export default Institute;

