import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

const NCERT = () => {
   
  const scollToRef1 = useRef();
  const scollToRef2 = useRef();
  const scollToRef3 = useRef();
  const scollToRef4 = useRef();
  const scollToRef5 = useRef();
  const scollToRef6 = useRef();
  const scollToRef7 = useRef();
  const scollToRef8 = useRef();
  const scollToRef9 = useRef();
  const scollToRef10 = useRef();
  const scollToRef11 = useRef();
  const scollToRef12 = useRef();




  return (
    <>
      
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
              <p>NCERT Solutions</p>
                <h1>CBSE</h1>
                <h5 className='text-white'>& Relavent Boards</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="first_main 12">
        <section className="first_year my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="first_year_info text-center">
                  <p>Select Your Class/Standard Here :</p>
                  <div className="button_div">
                  <div className="row">
                    <div className="col-md-2">
                    <button className="btn btn-primary mx-2" onClick={()=>scollToRef1.current.scrollIntoView({behavior:"smooth"})}>
                      <div className="button-inner">Class 1</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef2.current.scrollIntoView()}>
                      <div className="button-inner">Class 2</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef3.current.scrollIntoView()}>
                      <div className="button-inner">Class 3</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef4.current.scrollIntoView()}>
                      <div className="button-inner">Class 4</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef5.current.scrollIntoView()}>
                      <div className="button-inner">Class 5</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef6.current.scrollIntoView()}>
                      <div className="button-inner">Class 6</div>
                    </button>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2">
                    <button className="btn btn-primary mx-2" onClick={()=>scollToRef7.current.scrollIntoView()}>
                      <div className="button-inner">Class 7</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef8.current.scrollIntoView()}>
                      <div className="button-inner">Class 8</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef9.current.scrollIntoView()}>
                      <div className="button-inner">Class 9</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef10.current.scrollIntoView()}>
                      <div className="button-inner">Class 10</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef11.current.scrollIntoView()}>
                      <div className="button-inner">Class 11</div>
                    </button>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-primary" onClick={()=>scollToRef12.current.scrollIntoView()}>
                      <div className="button-inner">Class 12</div>
                    </button>
                    </div>
                  </div>
                  </div>
                </div>
                <div className="divider" ref={scollToRef12}></div>
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">Class 12</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">H.S. Second Year</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>Select Your Writer/Publication:</h5>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                     RD Sharma
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    NCERT
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    TS Grewal
                  </div>
                </button>
              </div>
            </div>
            </div>
          </div>
        </section>      
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>NCERT</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Banking
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        Business Studies
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Nature And Significance of Management</Link></li>
                          <li><Link>Principles of Managemnet</Link></li>
                          <li><Link>Business Environment</Link></li>
                          <li><Link>Planning</Link></li>
                          <li><Link>Organising</Link></li>
                          <li><Link>Directing</Link></li>
                          <li><Link>Staffing</Link></li>
                          <li><Link>Controlling</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Marketing</Link></li>
                          <li><Link>Consumer Protection</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                      >
                        Economics
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Introductions</Link></li>
                          <li><Link>National Income And Accounting</Link></li>
                          <li><Link>Money And Banking</Link></li>
                          <li><Link>Determinations Of Income And Employement</Link></li>
                          <li><Link>Governemnet Budget And The Economy</Link></li>
                          <li><Link>Open Economy-macroeconomics</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                      >
                        Alternative English
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Village Song</Link></li>
                          <li><Link>The Verger</Link></li>
                          <li><Link>The Scarecrow</Link></li>
                          <li><Link>The Gift of The Magi</Link></li>
                          <li><Link>The Brook</Link></li>
                          <li><Link>Testament of A Walker</Link></li>
                          <li><Link>Sita</Link></li>
                          <li><Link>Ozymandias Of Egypt</Link></li>
                          <li><Link>La Bella Dams Sans Merci</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>TS Grewal</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RD Sharma</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need more study material?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
        </section>
      </div>
      <div className="second_main 11" ref={scollToRef11}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="divider"></div>
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">Class 11</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    NCERT Solutions for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">H.S. First Year</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>Select Your Writer/Publication:</h5>
            </div>
            <div className="row">
             <div className="col-md-3">
             <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RD Sharma
                  </div>
                </button>
              </div>
             </div>
             <div className="col-md-3">
             <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    NCERT
                  </div>
                </button>
              </div>
             </div>
             <div className="col-md-3">
             <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RS Aggarwal
                  </div>
                </button>
              </div>
             </div>
             <div className="col-md-3">
             <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    TS Grewal
                  </div>
                </button>
              </div>
             </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>NCERT</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Banking
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        Business Studies
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Nature And Significance of Management</Link></li>
                          <li><Link>Principles of Managemnet</Link></li>
                          <li><Link>Business Environment</Link></li>
                          <li><Link>Planning</Link></li>
                          <li><Link>Organising</Link></li>
                          <li><Link>Directing</Link></li>
                          <li><Link>Staffing</Link></li>
                          <li><Link>Controlling</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Marketing</Link></li>
                          <li><Link>Consumer Protection</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                      >
                        Economics
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Introductions</Link></li>
                          <li><Link>National Income And Accounting</Link></li>
                          <li><Link>Money And Banking</Link></li>
                          <li><Link>Determinations Of Income And Employement</Link></li>
                          <li><Link>Governemnet Budget And The Economy</Link></li>
                          <li><Link>Open Economy-macroeconomics</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                      >
                        Alternative English
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Village Song</Link></li>
                          <li><Link>The Verger</Link></li>
                          <li><Link>The Scarecrow</Link></li>
                          <li><Link>The Gift of The Magi</Link></li>
                          <li><Link>The Brook</Link></li>
                          <li><Link>Testament of A Walker</Link></li>
                          <li><Link>Sita</Link></li>
                          <li><Link>Ozymandias Of Egypt</Link></li>
                          <li><Link>La Bella Dams Sans Merci</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>TS Grewal</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RS Aggarwal</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RD Sharma</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need more study material?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
        </section>
      </div>

      <div className="second_main 10" ref={scollToRef10}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 10</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
          <div class="headingblack text-center mb-2 pb-2">
              <h5>Select Your Writer/Publication:</h5>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RD Sharma
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    NCERT
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RS Aggarwal
                  </div>
                </button>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>NCERT</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RS Aggarwal</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RD Sharma</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need more study material?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
        </section>
      </div>
      
      <div className="second_main 9" ref={scollToRef9}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 9</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
          <div class="headingblack text-center mb-2 pb-2">
              <h5>Select Your Writer/Publication:</h5>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RD Sharma
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    NCERT
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RS Aggarwal
                  </div>
                </button>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>NCERT</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RS Aggarwal</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RD Sharma</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need more study material?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
        </section>
      </div>

      <div className="second_main 8" ref={scollToRef8}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 8</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
          <div class="headingblack text-center mb-2 pb-2">
              <h5>Select Your Writer/Publication:</h5>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RD Sharma
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    NCERT
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RS Aggarwal
                  </div>
                </button>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>NCERT</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RS Aggarwal</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RD Sharma</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need more study material?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
        </section>
      </div>

      <div className="second_main 7" ref={scollToRef7}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 7</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
          <div class="headingblack text-center mb-2 pb-2">
              <h5>Select Your Writer/Publication:</h5>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RD Sharma
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    NCERT
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RS Aggarwal
                  </div>
                </button>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>NCERT</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RS Aggarwal</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RD Sharma</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need more study material?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
        </section>
      </div>

      <div className="second_main 6" ref={scollToRef6}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 6</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
          <div class="headingblack text-center mb-2 pb-2">
              <h5>Select Your Writer/Publication:</h5>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RD Sharma
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    NCERT
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RS Aggarwal
                  </div>
                </button>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>NCERT</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RS Aggarwal</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RD Sharma</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need more study material?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
        </section>
      </div>

      <div className="second_main 5" ref={scollToRef5}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 5</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
          <div class="headingblack text-center mb-2 pb-2">
              <h5>Select Your Writer/Publication:</h5>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RD Sharma
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    NCERT
                  </div>
                </button>
              </div>
            </div>
            <div className="col-md-4">
            <div className="button_div text-center">
                <button className="btn btn-primary mx-2">
                  <div className="button-inner">
                    RS Aggarwal
                  </div>
                </button>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>NCERT</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RS Aggarwal</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>RD Sharma</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Mathematics
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need more study material?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
        </section>
      </div>

      <div className="second_main 4" ref={scollToRef4}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    NCERT Solutions for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 4</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="second_main 3" ref={scollToRef3}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 3</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="second_main 2" ref={scollToRef2}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 2</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="second_main 1" ref={scollToRef1}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">CBSE</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Materials for CBSE
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Class 1</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy-Latest Edition
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li><Link>Issue And Redemption Of Debentures</Link></li>
                          <li><Link>Partnership</Link></li>
                          <li><Link>Financial Statement</Link></li>
                          <li><Link>Accounting Ratios</Link></li>
                          <li><Link>Accounting For Not For Profit Orgainsation</Link></li>
                          <li><Link>Cash Flow Statements</Link></li>
                          <li><Link>Accounting For Share Capitals</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Accountancy-Old Edition
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                      <ul>
                          <li><Link>Reserve Bank Of India</Link></li>
                          <li><Link>Non Bank Financial Institutions</Link></li>
                          <li><Link>Negotiable Instrument</Link></li>
                          <li><Link>Financial Market</Link></li>
                          <li><Link>Employement of Funds</Link></li>
                          <li><Link>Commercial Banking In India</Link></li>
                          <li><Link>Cheque</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="college_intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                  <h2 className="my-2">
                  Central Board of Secondary Education (CBSE)
                  </h2>
                </div>
                <div className="content mt-5">
                <p className="my-4">
                  The Central Board of Secondary Education (CBSE) is a national level board of education in India for public and private schools, controlled and managed by Union Government of India. There are approximately 21,271 schools in India and 220 schools in 28 foreign countries affiliated to the CBSE.[1][2] From 309 schools in year 1962, as on year 2019, CBSE has 21,271 schools and 228 schools in 25 foreign countries. All schools affiliated to CBSE follow the NCERT curriculum.[3]
                  </p>
                  <p className="my-4">
                  The first education board to be set up in India was the Uttar Pradesh Board of High School and Intermediate Education in 1921, which was under the jurisdiction of Rajputana, Central India and Gwalior.[4] In 1929, the government of India set up a joint Board named “Board of High School and Intermediate Education, Rajputana”. This included Ajmer, Merwara, Central India, and Gwalior. Later it was confined to Ajmer, Bhopal and Vindhya Pradesh. In 1952, it became the “Central Board of Secondary Education”.
                  </p>
                  <p className="my-4">
                  CBSE conducts the final examinations for Class 10 and Class 12 every year in the month of March. The results are announced by the end of May.[5] The board earlier conducted the AIEEE Examination for admission to undergraduate courses in engineering and architecture in colleges across India. However, the AIEEE exam was merged with the IIT-Joint Entrance Exam (JEE) in 2013. The common examination is now called JEE (Main) and is henceforth conducted by National Testing Agency.
                  </p>
                  <p>
                  CBSE also conducts AIPMT (All India Pre Medical Test) for admission to major medical colleges in India. In 2014, the conduct of the National Eligibility Test for grant of a junior research fellowship and eligibility for assistant professor in institutions of higher learning was outsourced to CBSE.[6] Apart from these tests, CBSE also conducts the Central Teacher Eligibility Test and the Class X optional proficiency test.[6] With the addition of NET in 2014, the CBSE has become the largest exam conducting body in the world.[6][7]
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Class 10 </h5>
                  <p>
                  For promotion from Secondary level (Class IX-X) to Senior Secondary level (Class XI-XII), a student must obtain, for all subjects (or best 5 if 6 subjects are taken), 33% overall, without any minimum theory mark requirement. Originally, the passing criteria were set such that a student had to get 33% in both the theory and practical components. However, an exemption was initially granted for students writing the exam in 2018 as they went through the old CCE system in the previous year.[9] However, CBSE later extended this relief for students writing the exam from 2019 and later as well.[10] For a student who does not manage to pass up to two subjects, he/she can write the compartment in those subjects in July. For those who fail the compartment, or those who fail in three subjects or more, he/she must rewrite all the subjects taken in the next year
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Class 12</h5>
                  <p>
                  For class 12 students the promotion criteria are 33% overall, with 33% in both theory and practical examinations (if applicable). For a student who does not manage to pass on exactly one subject, he/she can write the compartment for that subject in July. For those who fail the compartment, or those who fail in two subjects or more, he/she must rewrite all the subjects taken in the next year.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NCERT