import React from "react";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./../Previous/Previous.css";

const PdfDemo = ({ file }) => {
  // const newPlugin = defaultLayoutPlugin();
  const embeddedPdfUrl = `${file}#toolbar=0`;

  return (
    <>
      <div className="view_pdf" style={{ position: "relative" }}>
        {/* Embed tag for displaying the PDF */}
        <embed
          src={embeddedPdfUrl}
          type="application/pdf"
          width="100%"
          height="800px"
        />
        <div
          style={{
            width: "40px",
            height: "60px",
            position: "absolute",
            opacity: "0",
            right: "33px",
            top: "22px",
            background: "black",
            zIndex: "10",
          }}
        >
          &nbsp;
        </div>
      </div>

      <div className="view_pdf1" style={{ position: "relative" }}>
        {/* Embed tag for displaying the PDF */}
        <iframe
          src={embeddedPdfUrl}
          type="application/pdf"
          width="100%"
          height="800px"
        />
        <div className="Responsive-design">&nbsp;</div>
        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            classname="inner_pdf_content"
            fileUrl={props.file}
            plugins={[newPlugin]}
          />
          ;
        </Worker> */}
      </div>
    </>
  );
};

export default PdfDemo;

// import React from "react";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import "./../Previous/Previous.css";

// const PdfDemo = ({ file }) => {
//   const newPlugin = defaultLayoutPlugin();

//   return (
//     <>
//       <div className="view_pdf">
//         {/* Embed tag for displaying the PDF */}
//         <embed src={file} type="application/pdf" width="100%" height="800px" />

//         {/* Viewer component (if you want to provide additional functionalities) */}
//         <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
//           <Viewer
//             className="inner_pdf_content"
//             fileUrl={file}
//             plugins={[newPlugin]}
//           />
//         </Worker>
//       </div>
//     </>
//   );
// };

// export default PdfDemo;
