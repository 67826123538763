const AdsComponentForPdfFileDownloadation = (props) => {
    return (
      <div className="ads ">
        <iframe
          className="border-2 border-primary"
          title="Google AdSense"
          srcDoc={`<html><head></head><body><ins class="adsbygoogle"
             style="display:block"
             data-ad-client="ca-pub-2178006875471084"
             data-ad-slot="${props?.dataAdSlot}"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>
             <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
             <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
             </body></html>`}
          style={{ border: "none", overflow: "hidden", width: "100%", height: "100%" }}
          allow="autoplay; encrypted-media"
        ></iframe>
      </div>
    );
  }
  
  export default AdsComponentForPdfFileDownloadation;
  