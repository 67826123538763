import AppContext from "./ContextForheader"
import { useState } from "react"

const ContextHeaderProvider = (props)=>{
    const [stateforheader,setStateForHeader] = useState(0);
    const [stateforhometabs,setStateForHomeTabs] = useState();
    const [isRendered,setIsrendered] = useState(false);

    return(
        <>
        <AppContext.Provider value={{stateforheader,setStateForHeader,stateforhometabs,setStateForHomeTabs,isRendered,setIsrendered}}>
        {props.children}
        </AppContext.Provider>
        </>
    )
}
export default ContextHeaderProvider;