import React from 'react'
import { Link } from 'react-router-dom'
import "./../Previous/Previous.css"

const BoardGuwahati = () => {
  return (
    <>
      <section className="ahsec_banner guahati_1">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="guahati_logo text-center">
                <img
                  src={require("./../../assets/images/gauhati-logo.png")}
                  alt=""
                />
              </div>
              <div className="gauhati_info">
                <Link
                  to="https://en.wikipedia.org/wiki/Gauhati_University"
                  target="_blank"
                >
                  <h3>Guwahati University</h3>
                  <h5>Learn More?</h5>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="guahati_content"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>
                <u>Bachelor of Commerce</u>
              </h2>
              <h3>Previous Year Question Papers - B.COM</h3>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-12">
              <div className="previous_pdf">
                <section className="Faq py-5 header">
                  <div className="container py-4">
                    <div className="row">
                      <div className="col-md-12 tabs_button">
                        <div
                          className="nav nav-pills"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <button
                            className="nav-link active"
                            id="v-pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-home"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-home"
                            aria-selected="true"
                          >
                            <i className="fa-regular fa-folder me-3"></i>{" "}
                            Semster 2
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-profile"
                            aria-selected="false"
                          >
                            <i className="fa-regular fa-folder me-3"></i>{" "}
                            Semster 3
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-messages-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-messages"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-messages"
                            aria-selected="false"
                          >
                            <i className="fa-regular fa-folder me-3"></i>{" "}
                            Semster 4
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-settings-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-settings"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-settings"
                            aria-selected="false"
                          >
                            <i className="fa-regular fa-folder me-3"></i>{" "}
                            Semster 5
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-settings1-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-settings1"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-settings1"
                            aria-selected="false"
                          >
                            <i className="fa-regular fa-folder me-3"></i>{" "}
                            Semster 6
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-home"
                            role="tabpanel"
                            aria-labelledby="v-pills-home-tab"
                          >
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    Business Statistics
                                  </button>
                                </h1>
                                <div
                                  id="collapseOne"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                      >
                                        2012
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingTwo"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    Financial Accounting
                                  </button>
                                </h1>
                                <div
                                  id="collapseTwo"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                      >
                                        2012
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    Human Resource Planning and Development
                                  </button>
                                </h1>
                                <div
                                  id="collapseThree"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                      >
                                        2012
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingFour"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                  >
                                    Micro Credit Institutions
                                  </button>
                                </h1>
                                <div
                                  id="collapseFour"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingFour"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                      >
                                        2012
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="v-pills-profile"
                            role="tabpanel"
                            aria-labelledby="v-pills-profile-tab"
                          >
                            <h1>Updating...</h1>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="v-pills-messages"
                            role="tabpanel"
                            aria-labelledby="v-pills-messages-tab"
                          >
                            <div className="accordion" id="accordionExample1">
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="true"
                                    aria-controls="collapseFive"
                                  >
                                    International Banking
                                  </button>
                                </h1>
                                <div
                                  id="collapseFive"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample1"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                      >
                                        2014
                                      </Link>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                      >
                                        2015{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingTwo"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix"
                                    aria-expanded="false"
                                    aria-controls="collapseSix"
                                  >
                                    Financial Services
                                  </button>
                                </h1>
                                <div
                                  id="collapseSix"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionExample1"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                      >
                                        2014
                                      </Link>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                      >
                                        2015{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                  >
                                    Cost and Management Accounting
                                  </button>
                                </h1>
                                <div
                                  id="collapseSeven"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionExample1"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                      >
                                        2014
                                      </Link>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingFour"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight"
                                    aria-expanded="false"
                                    aria-controls="collapseEight"
                                  >
                                    Auditing and Assurance
                                  </button>
                                </h1>
                                <div
                                  id="collapseEight"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingFour"
                                  data-bs-parent="#accordionExample1"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                      >
                                        2015{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingFour"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine"
                                    aria-expanded="false"
                                    aria-controls="collapseNine"
                                  >
                                    Indirect Taxation
                                  </button>
                                </h1>
                                <div
                                  id="collapseNine"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingFour"
                                  data-bs-parent="#accordionExample1"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                      >
                                        2015{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingFour"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTen"
                                    aria-expanded="false"
                                    aria-controls="collapseTen"
                                  >
                                    Advanced Financial Accounting
                                  </button>
                                </h1>
                                <div
                                  id="collapseTen"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingFour"
                                  data-bs-parent="#accordionExample1"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingFour"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse11"
                                    aria-expanded="false"
                                    aria-controls="collapse11"
                                  >
                                    Business Economics
                                  </button>
                                </h1>
                                <div
                                  id="collapse11"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingFour"
                                  data-bs-parent="#accordionExample1"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                      >
                                        2015
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="v-pills-settings"
                            role="tabpanel"
                            aria-labelledby="v-pills-settings-tab"
                          >
                            <h1>Updating....</h1>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="v-pills-settings1"
                            role="tabpanel"
                            aria-labelledby="v-pills-settings1-tab"
                          >
                            <div className="accordion" id="accordionExample2">
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse12"
                                    aria-expanded="true"
                                    aria-controls="collapse12"
                                  >
                                    Information Technology in Business
                                  </button>
                                </h1>
                                <div
                                  id="collapse12"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample2"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1lpp6TAWJWVnJSJMBRNz-25uG-HWZxWpf"
                                      >
                                        2015{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=12f0PoCs2DleXRnIvw8JnKJxjEWC_XZND"
                                      >
                                        2017{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1XK1wRlzQCZh01NM_nAIJvVjoEDCZgb8o"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingTwo"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse13"
                                    aria-expanded="false"
                                    aria-controls="collapse13"
                                  >
                                    Modern Banking Practices
                                  </button>
                                </h1>
                                <div
                                  id="collapse13"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionExample2"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=11Awtt37slY6pFrbBShv0K3fp_qkGYZBZ"
                                      >
                                        2015{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=10ntjAym5wrdsTRXb-5T388GnOdnJGpZx"
                                      >
                                        2017{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1A3qnM14wAZG84mSiMe2ijXOjPDWU4XX9"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse14"
                                    aria-expanded="false"
                                    aria-controls="collapse14"
                                  >
                                    Marketing of Services
                                  </button>
                                </h1>
                                <div
                                  id="collapse14"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionExample2"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1_tl4kftlbC8Raxvh6bqxeT3fHTGugPuN"
                                      >
                                        2015&nbsp;
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1KWHPi_B-pZPuELQPgD8m94pVSYXuVePz"
                                      >
                                        2017{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1I7PW-LQQnWCG3fljxI3R_e59l1_uL4iT"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h1
                                  className="accordion-header"
                                  id="headingFour"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse15"
                                    aria-expanded="false"
                                    aria-controls="collapse15"
                                  >
                                    Regulatory Framework of Business - II
                                  </button>
                                </h1>
                                <div
                                  id="collapse15"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingFour"
                                  data-bs-parent="#accordionExample2"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <strong>Year :</strong>{" "}
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=14rQ0yZeoDC5DQdRTP6q8i9dD-owJM2lX"
                                      >
                                        2015&nbsp;
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=1ENc3DBqJhp4wwy_i2yqhkzQw7oU8nB74"
                                      >
                                        2017{" "}
                                      </Link>
                                      <Link
                                        target="_blank"
                                        to="https://drive.google.com/open?id=19emqj8ROuHGV5m2e5nDbE1VpC6TprYZt"
                                      >
                                        2018
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BoardGuwahati