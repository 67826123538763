import { createSlice } from "@reduxjs/toolkit";
import { fetchTimerVal } from "../actions/timerActions";

const initialState = {
    isLoading : false,
    timerData : {},
    error : {}
}

export const timerSlices = createSlice({
      name : "timer",
      initialState,
      reducer : {},
      extraReducers : (builder)=>{
        builder
        .addCase(fetchTimerVal.pending , (state , action)=>{
            state.isLoading = true
        })
        .addCase(fetchTimerVal.fulfilled , (state , action)=>{
            state.isLoading = false
            state.timerData = action.payload
        })
        .addCase(fetchTimerVal.rejected , (state , action)=>{
            state.isLoading = false
            state.error = action.payload
        })
      }
    
})

export const { } = timerSlices.actions;
export default timerSlices.reducer;