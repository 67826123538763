import React from "react";
import EarnForm from "./EarnForm";
import "./NewEarnWith.Module.css";
function NewEarnWith() {
  return (
    <>
      <div className="fluid-container">
        <div className="row gx-0  Main-Box">
          <div className=" Typography-Box sectypo col-12 col-lg-6 d-flex justify-content-center flex-column align-items-center">
            <div className="fixwidetypo">
              <h1 className="text-center lg-text-start display-4 fw-bolder">
                Want to EARN some money?
              </h1>
              <h5>Well, we made it easier for you.</h5>
              <h5>
                For every Notes, Questions Papers and other study related
                materials, you can get paid by us.
              </h5>
            </div>
            <button className="button "> Know How</button>
          </div>
          <div className="col-12 transparent-block"></div>
          <div className="secImage col-12 col-lg-6 Image-Box">
            <img
              src={require("../../assets/images/EarnWith.png")}
              className="rounded mx-auto d-block    Earn-Image"
              alt=""
            />
          </div>
        </div>
      </div>
      {/*Steps section  */}

      <section className="step-section">
        <div className="container">
          <div className="row">
            <h1 className="Content-heading text-white">
              Start Earning in 3 Simple Steps
            </h1>
            <div className="col-md-4">
              <article className="Content-box">
                <img
                  src={require("../../assets/images/Num1.webp")}
                  // alt="image"
                  className="Content-image"
                />
                <h2>
                  Collect/Create <br></br>Study materials
                </h2>
                <p>
                  Materials such as Question Papers, Notes and any other useful
                  Study Materials maybe shared with us
                </p>
              </article>
            </div>
            <div className="col-md-4">
              <article className="Content-box">
                <img
                  src={require("../../assets/images/Num1.webp")}
                  // alt="image"
                  className="Content-image"
                />
                <h2>
                  Collect/Create <br></br>Study materials
                </h2>
                <p>
                  Materials such as Question Papers, Notes and any other useful
                  Study Materials maybe shared with us
                </p>
              </article>
            </div>
            <div className="col-md-4">
              <article className="Content-box">
                <img
                  src={require("../../assets/images/Num2.webp")}
                  // alt="image"
                  className="Content-image"
                />
                <h2>
                  Send it us for <br></br>
                  review
                </h2>
                <p>
                  You need to send us your materials' information on
                  <u style={{ cursor: "pointer" }}>earnings@rollingnotes.in</u>
                  where we review your materials and come up with a deal just
                  for you
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="step-section">
      <h1 className="Content-heading text-white">Start Earning in 3 Simple Steps</h1>

        
      
        <article className="Content-box last-element">
          <img src={require("../../assets/images/Num3.webp")} alt="image" className="Content-image" />
          <h2>
            You Get <br></br>paid
          </h2>
          <p>
            After the E-Mail communication deal is over, we pay you as per the
            deal highlights. (We promise that we will not use your materials
            until the deal is done)
          </p>
        </article>
      </div> */}

      {/* form module */}
      <EarnForm />
    </>
  );
}

export default NewEarnWith;
