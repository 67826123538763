import React from "react";
import "./Contribute.css";
import img1 from "../../assets/images/contributeImg1.jpg";
import img2 from "../../assets/images/contributeImg2.jpg";
import img3 from "../../assets/images/contributeImg3.jpg";
import img4 from "../../assets/images/contributeImg4.jpg";
import ContributeFooter from "./ContributeFooter";

function Contribute() {
  return (
    <>
      <div className="main-Box">
        <div className="positionBox fade-up" >
          <div className="Fixed-container"  data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1000">
            <h1 className="mainHeading display-1"> CONTRIBUTE </h1>
          </div>
        </div>

        {/* quote section */}
        <section className="container-fluid my-5">
          {/* container 1 */}
          <div className=" contributeImageContainer contributeImageContainer1 row  bg">
            <div
              className="col-12 col-md-6"
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <img
                src={img1}
                alt=""
                className="img-fluid rounded mx-auto d-block quoteImage imgCont"
              />
            </div>
            <div
              className="col-12 col-md-5  quote-Typo"
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <i className="fa-sharp fa-solid fa-quote-left fa-4x  quote-Icon "></i>
              <p className="">Mind is never a problem. Mindset is.</p>
              <h2 className="h3">Narendra Modi</h2>
            </div>
          </div>
          {/* container 2 */}
          <div className=" contributeImageContainer contributeImageContainer2 row  bg">
            <div
              className="col-12 col-md-5  quote-Typo "
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <i className="fa-sharp fa-solid fa-quote-left fa-4x quoteImage quote-Icon "></i>
              <p className="">
                Education is the manifestation of the perfection already in man.
                Religion is themanifestation of the Divinity already in man
              </p>
              <h2 className="h3">Swami Vivekananda</h2>
            </div>
            <div
              className="col-12 col-md-6 imgCont "
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <img
                src={img2}
                alt=""
                className="img-fluid rounded mx-auto d-block quoteImage "
              />
            </div>
          </div>
          {/* container 3 */}
          <div className=" contributeImageContainer contributeImageContainer1 row  bg">
            <div
              className="col-12 col-md-6 imgCont "
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <img
                src={img3}
                alt=""
                className="img-fluid rounded mx-auto d-block quoteImage "
              />
            </div>
            <div
              className="col-12 col-md-5  quote-Typo "
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <i className="fa-sharp fa-solid fa-quote-left fa-4x  quote-Icon "></i>
              <p className="">
                Education is the manifestation of the perfection already in man.
                Religion is themanifestation of the Divinity already in man
              </p>
              <h2 className="h3">Albert Einstein</h2>
            </div>
          </div>
          {/* container 4 */}
          <div className=" contributeImageContainer contributeImageContainer2 row  bg">
            <div
              className="col-12 col-md-5  quote-Typo "
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <i className="fa-sharp fa-solid fa-quote-left fa-4x  quote-Icon "></i>
              <p className="">
                One Child, One Teacher, One Pen and One Book can change the
                World
              </p>
              <h2 className="h3">Malala Yousafzai</h2>
            </div>
            <div
              className="col-12 col-md-6  imgCont"
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <img
                src={img4}
                alt=""
                className="img-fluid rounded mx-auto d-block quoteImage "
              />
            </div>
          </div>
        </section>
      </div>
      <ContributeFooter/>
    </>
  );
}

export default Contribute;
