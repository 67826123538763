import React from "react";
import { Link } from "react-router-dom";

const Flowcharts = () => {
  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div
              className="col-md-12 h-100"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="ahsec_title">
                <p>Flowcharts</p>
                <h1>AHSEC</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="second_main">
        <section className="first_year mt-4">
          <div className="container">
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">Class 12</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Flowcharts for Assam Higher Secondary
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">H.S. Second Year</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>commerce</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Business Studies
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <ul>
                          <li>
                            <Link>Nature And Significance of Management</Link>
                          </li>
                          <li>
                            <Link>Principles of Managemnet</Link>
                          </li>
                          <li>
                            <Link>Business Environment</Link>
                          </li>
                          <li>
                            <Link>Planning</Link>
                          </li>
                          <li>
                            <Link>Organising</Link>
                          </li>
                          <li>
                            <Link>Directing</Link>
                          </li>
                          <li>
                            <Link>Staffing</Link>
                          </li>
                          <li>
                            <Link>Controlling</Link>
                          </li>
                          <li>
                            <Link>Financial Market</Link>
                          </li>
                          <li>
                            <Link>Marketing</Link>
                          </li>
                          <li>
                            <Link>Consumer Protection</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need More Study Materials?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
          <div className="divider"></div>
        </section>
      </div>
      <section className="college_intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                  <h2 className="my-2">
                    About Assam Higher Secondary Education Council (AHSEC)
                  </h2>
                  <p className="my-4">
                    AHSEC an acronym for Assam Higher Secondary Education
                    Council is a board of education under the&nbsp;state
                    government of Assam. It is the regulating body for higher
                    secondary (10+2 stage) education in the state of
                    Assam.&nbsp; The Assam Higher Secondary Education Council
                    was established by the Assam government on 1 June 1984 to
                    regulate, supervise and develop the system of higher
                    secondary education in the State of Assam.
                  </p>
                </div>
                <div className="content mt-5">
                  <h5 className="my-2">
                    Functions Of Assam Higher Secondary Education Council
                  </h5>
                  <p className="my-4">
                    One of the main operations of the AHSEC (Assam Higher
                    Secondary Education Council) is to enforce the rules and
                    regulations, that have been set for the schools that are
                    affiliated with the Assam Board. Other functions also
                    include prescribing the syllabus, devising courses of study
                    like the textbooks, conducting exams and so on.
                  </p>
                </div>
                <div className="content mt-5">
                  <h5 className="my-2">
                    Exam Conducted by Assam Higher Secondary Education Council
                  </h5>
                  <p className="my-4">
                    The Assam Higher Secondary Education Council conducts Class
                    12 exam every year.&nbsp;AHSEC or the Assam Higher Secondary
                    Education Council supervises, regulates, and develops the
                    system of 12th standard in the state. The 12th standard
                    exams in Assam are conducted for Science, Commerce, Arts and
                    Vocational Groups.
                  </p>
                </div>
                <div className="content mt-5">
                  <h5 className="my-2">About Previous Year Question Papers</h5>
                  <p className="my-3">
                    As of 2014, the Assam Higher Secondary Education Council has
                    adopted the NCERT curriculum for class 12 students.
                    Therefore the exams are also held in the NCERT pattern. The
                    board has further stated that by adopting such curriculum it
                    will bring greater benefit to the students as they will be
                    on par with students from other boards. However, class 12
                    exams are very important for students as the results they
                    obtain will determine the careers they choose in the future.
                  </p>
                  <p className="my-3 pe-4">
                    Therefore, students need to prepare well for the exam. Apart
                    from studying from the&nbsp;Assam Board textbooks, another
                    way student can study productively is by practising and
                    solving question papers. Students can further use and solve
                    question papers to know how the entire exam paper will be
                    set. Additionally, by making use of question papers,
                    students will;
                  </p>
                  <ul className="my-3">
                    <li>
                      Understand clearly how the exam paper pattern will be.
                    </li>
                    <li>
                      Know the repeated topics and what to focus on while
                      studying.
                    </li>
                    <li>Learn about the marking scheme.</li>
                    <li>Figure out the time needed to solve each question.</li>
                  </ul>
                  <p>
                    Students can access a set of Assam board class 12 question
                    papers below and refer them to test their preparation level
                    as well get an idea about the types of questions that are
                    asked in each subject. These question papers will serve a
                    great purpose of helping students prepare well for the board
                    exams.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Flowcharts;
