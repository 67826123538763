import React, { useRef } from "react";
import { Link } from "react-router-dom";

const StudyNotes = () => {
  const scollToRef1 = useRef();
  const scollToRef2 = useRef();
  const scollToRef3 = useRef();

  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <p>Study Notes</p>
                <h1>Dibrugarh University</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="first_main 12">
        <section className="first_year my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="first_year_info text-center">
                  <p>Select Your Course Here :</p>
                  <div className="button_div">
                    <div className="row">
                      <div className="col-md-4">
                        <button className="btn btn-primary mx-2" onClick={()=>scollToRef1.current.scrollIntoView()}>
                          <div className="button-inner">B.Com</div>
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button className="btn btn-primary" onClick={()=>scollToRef2.current.scrollIntoView()}>
                          <div className="button-inner">MBA</div>
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button className="btn btn-primary" onClick={()=>scollToRef3.current.scrollIntoView()}>
                          <div className="button-inner">BBA</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider" ref={scollToRef1}></div>
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">B.Com</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Study Notes for B.Com
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">
                      Bachelor of Commerce
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div className="row my-4">
              <div className="previous_pdf previous_one d-flex">
                <div className="col-md-12">
                  <div
                    className="nav  nav-pills "
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 1
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 3
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 5
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Business Statistics
                            </button>
                          </h1>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Human Resource Planning and Development
                            </button>
                          </h1>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Micro Credit Institutions
                            </button>
                          </h1>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <h1>Updating...</h1>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              International Banking
                            </button>
                          </h1>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Financial Services
                            </button>
                          </h1>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Cost and Management Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Auditing and Assurance
                            </button>
                          </h1>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Indirect Taxation
                            </button>
                          </h1>
                          <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                >
                                  2015
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Advanced Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                            >
                              Business Economics
                            </button>
                          </h1>
                          <div
                            id="collapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                >
                                  2015
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Faq first_year my-5" ref={scollToRef3}>
          <div className="container">
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">BBA</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                     Study Notes for  BBA
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Bachelor of Business Administration</div>
                  </div>
                </h2>
              </div>
            </div>
            <div className="row my-4">
              <div className="previous_pdf previous_one d-flex">
                <div className="col-md-12">
                  <div
                    className="nav nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 2
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 3
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 4
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Business Statistics
                            </button>
                          </h1>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Human Resource Planning and Development
                            </button>
                          </h1>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Micro Credit Institutions
                            </button>
                          </h1>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <h1>Updating...</h1>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              International Banking
                            </button>
                          </h1>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Financial Services
                            </button>
                          </h1>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Cost and Management Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Auditing and Assurance
                            </button>
                          </h1>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Indirect Taxation
                            </button>
                          </h1>
                          <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                >
                                  2015
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Advanced Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                            >
                              Business Economics
                            </button>
                          </h1>
                          <div
                            id="collapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                >
                                  2015
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Faq first_year my-5" ref={scollToRef2}>
          <div className="container">
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">MBA</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                     Study Notes for MBA
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Master of Business Administration</div>
                  </div>
                </h2>
              </div>
            </div>
            <div className="row my-4">
              <div className="previous_pdf previous_one d-flex">
                <div className="col-md-12">
                  <div
                    className="nav nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 2
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 3
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 4
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Business Statistics
                            </button>
                          </h1>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Human Resource Planning and Development
                            </button>
                          </h1>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Micro Credit Institutions
                            </button>
                          </h1>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <h1>Updating...</h1>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              International Banking
                            </button>
                          </h1>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Financial Services
                            </button>
                          </h1>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Cost and Management Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Auditing and Assurance
                            </button>
                          </h1>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Indirect Taxation
                            </button>
                          </h1>
                          <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                >
                                  2015
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Advanced Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                            >
                              Business Economics
                            </button>
                          </h1>
                          <div
                            id="collapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                >
                                  2015
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="college_intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                  <h2 className="my-2">About Dibrugarh University</h2>
                </div>
                <div className="content mt-5">
                  <p className="my-4">
                    Dibrugarh University is a university in the Indian state of
                    Assam. It was set up in 1965 under the provisions of the
                    Dibrugarh University Act, 1965,[1] enacted by the Assam
                    Legislative Assembly. It is a teaching-cum-affiliating
                    university.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Location and Campus</h5>
                  <p>
                    The Dibrugarh University Campus is located at Rajabheta, 5
                    km from Dibrugarh Town (27° 29′ North Latitude and 94° 55′
                    East Longitude) and is spread over an area of 500 acres.[2]
                    The NH15 (old NH37) bisects the main campus from the other,
                    the latter primarily comprising teacher’s and officer’s
                    residence.
                  </p>
                  <p>
                    The Dibrugarh district is well known for its vast mineral
                    resources (including oil, natural gas and coal), flora and
                    fauna and many tea plantations. The diverse tribes with
                    their distinct dialects, customs, traditions and culture
                    make this area attractive to students of anthropology and
                    sociology, art and culture.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Accreditation</h5>
                  <p>
                    It is accredited by the National Assessment and
                    Accreditation Council, with an ‘A’ Grade in 2017.[3] This
                    accreditation status is valid for a period of five years.[4]
                    Dibrugarh University is a member of the Association of
                    Indian Universities (AIU)[5] and the Association of
                    Commonwealth Universities (ACU).[6]
                  </p>
                  <p>Academics</p>
                  <p>Centre for Computer Studies</p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">
                    Administrative Office of the University
                  </h5>
                  <p>
                    The Centre for Computer Studies (CCS) originates in a
                    Computer Centre established in 1976, which taught a
                    “Six-month Certificate Course on Computer Programming”. In
                    2004 it was upgraded to the Centre for Computer Studies and
                    started “Post-Graduate Diploma in Computer Application
                    (PGDCA)”. BCA was introduced in July 2004 and MCA and B.Sc
                    IT in January and July 2007. The center has three computer
                    laboratories as well as its own library.
                  </p>
                  <p>
                    The MCA programme of CCS is approved by All India Council
                    for Technical Education (AICTE). It is also an accredited
                    study centre for Indira Gandhi National Open University.
                  </p>
                  <p>
                    Admission to CCS is based on a state level Common Admission
                    Test (CAT). Candidates are then called for a personal
                    interview, counseling and admission. In addition, some
                    programmes have requirements for passing 10+2 level with
                    mathematics and/or statistics at some threshold mark in
                    aggregate.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">
                    Dibrugarh University Institute of Engineering and Technology
                  </h5>
                  <p>
                    The Dibrugarh University Institute of Engineering and
                    Technology (DUIET) was established in 2009 as a constituent
                    institute and an integral part the university. The institute
                    was established with due approval from the All India Council
                    for Technical Education (AICTE), New Delhi and necessary
                    permission from the Assam state government.
                  </p>
                  <p>
                    The first session of the institute commenced in August 2009
                    with an intake capacity of 60 in each of the following
                    disciplines to a B. Tech. degree:
                  </p>
                  <ul>
                    <li>Electronics and Communication Engineering</li>
                    <li>Computer Science and Engineering</li>
                    <li>Petroleum Engineering</li>
                    <li>Mechanical Engineering</li>
                    <li>
                      DUIET is located in the Dibrugarh University campus. It
                      has excellent infrastructure and facilities. The
                      administration of DUIET is headed by Dr. M.C.Bora who is
                      the institute director.
                    </li>
                  </ul>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Centre for Management Studies</h5>
                  <p>
                    The Centre for Management Studies (CMSDU) is a management
                    school that is part of Dibrugarh University. It started
                    functioning from 3 February 2003, with the first batch of
                    Master of Business Administration (MBA) students enrolled
                    into the two-year programme.[citation needed] The three-year
                    Bachelor of Business Administration (BBA) and the three-year
                    MBA (part-time) classes started in 2004.[citation needed]
                    2006 saw the launch of the one-year PGDTM (Post Graduate
                    Diploma in Tourism Management) Programme.[citation needed]
                    It is also running a Ph.D. programme which can be pursued
                    both part-time as well as full-time.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Centre for Juridical Studies</h5>
                  <p>
                    The centre for juridical studies is a Centre of Dibrugarh
                    University that imparts both undergraduate and Post Graduate
                    legal education. The centre was established in the year
                    2006. The Centre runs BALL.B (H)and LL.M (Constitutional
                    Law, Corporate Law and Criminal Law Groups) courses.[7]
                  </p>
                  <p>
                    Eligibility: For B.A.LL.B. (H): A candidate for admission to
                    the Five- year B.A.LL.B. (Hons) Course shall have passed the
                    Higher Secondary Examination (10+2) or an equivalent
                    examination securing at least 45% in aggregate of the total
                    marks. Provided that there shall be a relaxation of 5% marks
                    for SC/ST/OBC candidates. For LL.M.: A candidate to take
                    admission into LL.M. Course must have 50% marks in aggregate
                    in 3 year LL.B. or 5 year B.A.LL.B. (H) Course recognized by
                    University Grants Commission (UGC) and Bar Council of India
                    (BCI). The candidates appearing in final year examination of
                    the aforesaid Programmes may also apply but at the time of
                    admission they must produce the mark sheet and pass
                    Certificate in original.
                  </p>
                  <p>
                    Admission Procedure: The admission to the aforesaid
                    Programmes will be made only on merit basis through an
                    Admission Test and (viva-voce/wherever applicable) to be
                    conducted by the University. Admission cannot be claimed by
                    any applicant as a matter of right. Note: In case of LL.M
                    only, on being selected for admission, the applicant has to
                    appear before admission committee at the Centre for
                    Juridical Studies, Dibrugarh University with all original
                    certificates, mark sheets and other requirements on
                    scheduled date and time. If any selected candidate is unable
                    to appear personally, he may send his authorized
                    representative with the required documents. If any applicant
                    fails to deposit prescribed fee within the stipulated time,
                    his/her admission will automatically stand cancelled. If any
                    selected candidate or his authorised representative does not
                    appear before Admission Committee on scheduled date and
                    time, his/her candidature shall be deemed to be cancelled
                    without any intimation in writing.[7]
                  </p>
                  <p>
                    Dibrugarh University was ranked in the 101–150 band overall
                    in India by the National Institutional Ranking Framework
                    (NIRF) in 2019,[12] 86 among universities[13] and 35 in the
                    pharmacy ranking.[11]
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudyNotes;
