import { createSlice } from '@reduxjs/toolkit'


const initialState= {
    addVal:""
   }
export const globalAdsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    isAddvisible:(state, action)=>{
         return {...state, addVal : action.payload}
    }
  },
})


export const { isAddvisible } = globalAdsSlice.actions

export default globalAdsSlice.reducer