import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import TestimonialCards from "../../../components/TestimonialCard/TestimonialCards";
import "./Testimonial.css"

export default function Testimonial() {

  const [testimonialsData, setTestimonialsData]= useState([])



    const getTestimonialData= async()=>{
    const data = await fetch("https://rollingnotes.in/app/backend/api_v2/fetch_testimonial.php");
    const jsonData = await data.json();
    console.log(jsonData)
    console.log(jsonData?.testimonials,"jsonData?.testimonials");
    setTestimonialsData(jsonData?.testimonials)
  }

  useEffect(()=>{
    getTestimonialData()
  },[])
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    useEffect(() => {
      console.log(testimonialsData,"testimonialsData")
    }, [testimonialsData])
    

  return (
    <>
      <section className="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <div className="testimonial_title text-center py-3 mt-4">
              <h5>Testimonial</h5>
              <h2>What Student Saying</h2>
            </div>
              <Slider {...settings}>
           {
            Array.isArray(testimonialsData) && testimonialsData?.length > 0 ? testimonialsData?.map((item,index) => {
              return  <TestimonialCards data={item} index={index}/>
              
            }) : <div>No data found</div>
           }
               
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
